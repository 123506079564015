import {
  createGlobalStyle,
} from 'styled-components';
import globalStyles from './common/utilsClient/globalStyles';

const GlobalStyle = createGlobalStyle`
  ${globalStyles};

  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
  }

  /* App */

  #root {
    &,
    & > div {
      height: 100%;
      overflow: hidden;
    }
  }

  /* List */

  ul.ant-list-item-action {
    
    li {
      margin-bottom: 0;
    }
  }

  ul:not(role=menu) li {
    margin-bottom: 0.5em;
  }

  /* Media Player */

  .plyr--video {
    background: none !important;
  }

  /* Helpers */

  .overflow-hidden {
    overflow: hidden !important;
  }

  .rtl {
    direction: rtl;
  }
`;

export default GlobalStyle;
