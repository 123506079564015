import {
  useEffect,
  useState,
} from 'react';

export default () => {
  const media = matchMedia('(prefers-color-scheme: dark)');
  const [
    scheme,
    setScheme,
  ] = useState(media.matches ? 'dark' : 'light');

  useEffect(() => {
    const handleMediaChange = event => setScheme(event.matches ? 'dark' : 'light');

    if (matchMedia.addEventListener) {
      media.addEventListener('change', handleMediaChange);
    } else {
      // polyfil for Safari and IE
      media.addListener((mql) => {
        if (mql.matches) {
          handleMediaChange(mql);
        }
      });
    }

    return () => (media.removeEventListener
      ? media.removeEventListener('change', handleMediaChange)
      : media.removeListener(handleMediaChange));
  });

  return scheme;
};
