import {
  compose,
  createStore,
  applyMiddleware,
} from 'redux';
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'localforage';
import {
  routerMiddleware,
} from 'connected-react-router';
import {
  createReducer,
} from './rootReducer';
import {
  createMiddleware as createQuestionnaireMiddleware,
} from './questionnaire';
import {
  createMiddleware as createStageMiddleware,
} from './stage';
import {
  createMiddleware as createPreferencesMiddleware,
} from './preferences';
import {
  createMiddleware as createTokenMiddleware,
} from './token';
import {
  clockMiddleware,
} from '../common/utils/clock';
import isDebug from '../utils/isDebug';

let enhancer;
const identity = x => x;

if (isDebug) {
  enhancer = compose(
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({}) // eslint-disable-line no-underscore-dangle
      : identity,
  );
} else {
  enhancer = compose(identity);
}

export const enhanceReducer = reducer => persistReducer(
  {
    storage,
    key: 'zedoc',
    whitelist: [
      'storage',
      'stage',
      'preferences',
      'token',
    ],
  },
  reducer,
);

export default ({
  history,
  client,
}) => {
  let persistor;

  const onNewReducer = (reducer) => {
    // NOTE: We need to use persistor instead of store,
    //       because otherwise persist reducer will not
    //       have persist capabilities anymore.
    persistor.replaceReducer(enhanceReducer(reducer));
  };

  // eslint-disable-next-line prefer-const
  const store = createStore(
    enhanceReducer(createReducer(history, onNewReducer)),
    {},
    compose(
      applyMiddleware(
        routerMiddleware(history),
        thunk.withExtraArgument(client),
        createQuestionnaireMiddleware({
          client,
        }),
        createStageMiddleware({
          client,
        }),
        createPreferencesMiddleware({
          client,
        }),
        createTokenMiddleware(),
        clockMiddleware,
      ),
      enhancer,
    ),
  );

  persistor = persistStore(store);

  if (process.env.NODE_ENV !== 'production') {
    if (typeof module !== 'undefined' && module.hot) {
      module.hot.accept('./rootReducer.js', () => onNewReducer(
        // eslint-disable-next-line global-require
        require('./rootReducer.js').createReducer(history, onNewReducer),
      ));
    }
  }

  return {
    store,
    persistor,
  };
};
