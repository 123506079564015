export const CONTACT_METHOD_EMAIL = 'email';
export const CONTACT_METHOD_TEXT_MESSAGE = 'text_message';
export const CONTACT_METHOD_MOBILE_APP = 'mobile_app';
export const CONTACT_METHOD_PAPER_BASED = 'paper_based';
export const CONTACT_METHOD_PHONE_CALL = 'phone_call';

export const CONTACT_TYPE_PERSONAL = 'Personal';
export const CONTACT_TYPE_HOME = 'Home';
export const CONTACT_TYPE_WORK = 'Work';
export const CONTACT_TYPE_MOBILE = 'Mobile';
export const CONTACT_TYPE_TEMPORARY = 'Temporary';
export const CONTACT_TYPE_OLD = 'Old';

export const PHONE_TYPES = [
  CONTACT_TYPE_PERSONAL,
  CONTACT_TYPE_HOME,
  CONTACT_TYPE_WORK,
  CONTACT_TYPE_MOBILE,
  CONTACT_TYPE_TEMPORARY,
  CONTACT_TYPE_OLD,
];

export const EMAIL_TYPES = [
  CONTACT_TYPE_PERSONAL,
  CONTACT_TYPE_HOME,
  CONTACT_TYPE_WORK,
  CONTACT_TYPE_MOBILE,
  CONTACT_TYPE_TEMPORARY,
  CONTACT_TYPE_OLD,
];

export const PHONE_TYPE_OPTIONS = [
  {
    value: CONTACT_TYPE_HOME,
    label: 'home',
  },
  {
    value: CONTACT_TYPE_WORK,
    label: 'work',
  },
  {
    value: CONTACT_TYPE_MOBILE,
    label: 'mobile',
  },
];

export const EMAIL_TYPE_OPTIONS = [
  {
    value: CONTACT_TYPE_PERSONAL,
    label: 'personal',
  },
  {
    value: CONTACT_TYPE_WORK,
    label: 'work',
  },
];

export const GENDER_MALE = 'Male';
export const GENDER_FEMALE = 'Female';
export const GENDER_DIVERSE = 'Diverse';
export const GENDER_UNKNOWN = 'Unknown';

export const CONTACT_METHODS = [
  {
    system: CONTACT_METHOD_EMAIL,
    label: 'Email',
    icon: 'email',
    needsValue: true,
  },
  {
    system: CONTACT_METHOD_TEXT_MESSAGE,
    label: 'Phone',
    icon: 'textsms',
    needsValue: true,
  },
  {
    system: CONTACT_METHOD_MOBILE_APP,
    label: '',
    icon: 'smartphone',
    needsValue: false,
  },
  {
    system: CONTACT_METHOD_PAPER_BASED,
    label: '',
    icon: 'description',
    needsValue: false,
  },
  {
    system: CONTACT_METHOD_PHONE_CALL,
    label: 'Phone',
    icon: 'call',
    needsValue: true,
  },
];

const labels = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
];

export const CONTACT_PREFERENCE_OPTIONS = [
  {
    value: '-1',
    label: 'DO NOT USE',
  },
];

for (let i = 0; i < CONTACT_METHODS.length; i += 1) {
  CONTACT_PREFERENCE_OPTIONS.push({
    value: i.toString(),
    label: `${labels[i] || `${i + 1}th`} method`,
  });
}
