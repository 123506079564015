import React, {
  Suspense,
  lazy,
} from 'react';
import Skeleton from '../Skeleton';

const LazyMarkdown = lazy(() => import(/* webpackChunkName: "Markdown" */ './Markdown'));

export default props => (
  <Suspense
    fallback={(
      <Skeleton
        active
        title={false}
        paragraph={{
          rows: 1,
        }}
      />
)}
  >
    <LazyMarkdown {...props} />
  </Suspense>
);
