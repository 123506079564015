import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import isPlainObject from 'lodash/isPlainObject';
import {
  defaultMemoize,
  createSelector,
} from 'reselect';
import {
  compose,
} from 'recompose';
import React from 'react';
import {
  connect,
} from 'react-redux';
import Context, {
  withContext,
} from './QuestionnaireContext';
import SelectorsHub from './SelectorsHub';
import Model from '../../models/Questionnaire';
import {
  clearQuestionnaire,
  initializeQuestionnaire,
} from './actions';
import shallowEqual from '../../utilsClient/shallowEqual';
import {
  ownPropsSelector,
  property,
  constant,
} from '../../utilsClient/selectors';
import toSelector from '../../utils/toSelector';
import {
  liftContextSelectors,
} from './selectors';

const reinitializeKeys = [
  'initialValues',
];

class Questionnaire extends React.Component {
  static create(createOptions, createCustomPropsSelector) {
    return BaseComponent => connect(() => {
      let context;
      if (typeof createOptions === 'function') {
        context = new SelectorsHub(createOptions());
      } else if (isPlainObject(createOptions)) {
        context = new SelectorsHub(createOptions);
      }
      const ownProps = ownPropsSelector();
      const selectContext = createSelector(
        ownProps,
        props => context.bindProps(props),
      );
      const customProps = createCustomPropsSelector
        ? toSelector(
          createCustomPropsSelector({
            ...context.select,
            context: constant(selectContext),
          }),
        )
        : constant(null);
      return toSelector({
        customProps,
        ownProps,
        context: selectContext,
      });
    })(({
      context,
      ownProps,
      customProps,
    }) => (
      <Context.Provider value={context}>
        <BaseComponent
          {...ownProps}
          {...customProps}
        />
      </Context.Provider>
    ));
  }

  static connect(createMapStateToProps) {
    return compose(
      withContext(),
      connect(() => createMapStateToProps(liftContextSelectors(property('context')))),
    );
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.getContext = defaultMemoize(
      params => new SelectorsHub(params),
      shallowEqual,
    );
  }

  componentDidMount() {
    const {
      readOnly,
    } = this.props;
    if (readOnly) {
      return;
    }
    this.resetState();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.allowReinitialize) {
      return !shallowEqual(this.props, nextProps);
    }
    return !shallowEqual(
      omit(this.props, reinitializeKeys),
      omit(nextProps, reinitializeKeys),
    );
  }

  componentDidUpdate(prevProps) {
    const {
      name,
      readOnly,
      dispatch,
      allowReinitialize,
      initialValues,
    } = this.props;
    if (readOnly) {
      return;
    }
    if (name !== prevProps.name) {
      dispatch(clearQuestionnaire(prevProps.name));
      this.resetState();
      return;
    }

    if (allowReinitialize && initialValues !== prevProps.initialValues) {
      // NOTE: This updates all options as well.
      this.resetState();
    }
  }

  componentWillUnmount() {
    const {
      name,
      dispatch,
    } = this.props;
    dispatch(clearQuestionnaire(name));
  }

  resetState() {
    const {
      dispatch,
      name,
      initialValues,
    } = this.props;
    dispatch(initializeQuestionnaire(name, initialValues));
  }

  render() {
    const {
      name,
      questionnaire,
      properties,
      variables,
      sortedBy,
      sessionId,
      answersSheetId,
      debounceEdit,
      disabled,
      children,
    } = this.props;
    return (
      <Context.Provider
        value={this.getContext({
          name,
          questionnaire,
          properties,
          variables,
          sortedBy,
          sessionId,
          answersSheetId,
          debounceEdit,
          disabled,
        })}
      >
        {children}
      </Context.Provider>
    );
  }
}

Questionnaire.propTypes = {
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  variables: PropTypes.objectOf(PropTypes.any),
  properties: PropTypes.objectOf(PropTypes.any),
  sortedBy: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  allowReinitialize: PropTypes.bool,
  questionnaire: PropTypes.instanceOf(Model).isRequired,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  debounceEdit: PropTypes.number,
  sessionId: PropTypes.string,
  answersSheetId: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Questionnaire.defaultProps = {
  initialValues: null,
  variables: null,
  properties: null,
  sortedBy: null,
  allowReinitialize: false,
  sessionId: null,
  answersSheetId: null,
  debounceEdit: 0,
  disabled: false,
  readOnly: false,
};

export default connect(
  null,
  null,
)(Questionnaire);
