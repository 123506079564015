import last from 'lodash/last';
import get from 'lodash/get';
import every from 'lodash/every';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import {
  useMemo,
} from 'react';
import gql from 'graphql-tag';
import {
  useQuery,
} from 'react-apollo';
import {
  useSelector,
} from 'react-redux';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  constant,
} from '../common/utilsClient/selectors';
import Questionnaire from '../common/models/Questionnaire';
import QuestionnaireTranslation from '../common/models/QuestionnaireTranslation';
import {
  toFormValues,
} from '../common/utils/responses';
import {
  createDynamicQuestionnaireSelectors,
} from '../common/containers/Questionnaire';
import {
  load,
  isCompleted as createIsCompleted,
  getStaged,
} from '../store/stage';
import parseQuestionnaire from './parseQuestionnaire';
import defaultQuestionFilter from './questionFilter';

const makeSelector = ({
  dataQuestionnaire,
  dataTranslation,
  answersSheetId,
}) => {
  const selectQuestionnaire = parseQuestionnaire(
    constant(get(dataQuestionnaire, 'answersSheet.questionnaire.data')),
    constant(
      get(dataTranslation, 'answersSheet.questionnaire.translation.data'),
    ),
  );
  const selectTranslations = constant(
    get(dataQuestionnaire, 'answersSheet.questionnaire.translations'),
  );
  const selectQuestionFilter = constant(defaultQuestionFilter);
  const selectAnswersSheetId = constant(answersSheetId);
  const selectCurrentResponses = createSelector(
    load(selectAnswersSheetId),
    staged => (staged && staged.responses) || [],
  );
  const selectLastAnsweredQuestionId = createSelector(
    selectCurrentResponses,
    (responses) => {
      if (responses && responses.length > 0) {
        return last(responses).questionId;
      }
      return undefined;
    },
  );
  const select = createDynamicQuestionnaireSelectors({
    selectRawFormValues: createSelector(
      selectCurrentResponses,
      responses => toFormValues(responses),
    ),
    selectQuestionnaire: createSelector(
      selectQuestionnaire,
      questionnaire => questionnaire || new Questionnaire({}),
    ),
  });
  const selectCurrentQuestionId = createSelector(
    selectQuestionFilter,
    selectLastAnsweredQuestionId,
    select.questionCursor(selectLastAnsweredQuestionId, undefined, {
      force: true,
    }),
    (questionFilter, lastAnsweredQuestionId, currentCursor) => {
      // NOTE: If cursor is not valid, e.g. questionnaire has not loaded yet,
      //       both nextQuestionIdWhere and firstQuestionIdWhere will return null.
      //       If lastAnsweredQuestionId turns out to be the last question of the
      //       questionnaire, nextQuestionIdWhere() will return undefined, that's
      //       we use alternative value.
      if (lastAnsweredQuestionId) {
        // TODO: Later on we can perform the "CAT query" at this stage
        //       to receive the next questionId based on current responses.
        if (currentCursor.hasErrors()) {
          return lastAnsweredQuestionId;
        }
        return (
          currentCursor.nextQuestionIdWhere(questionFilter) ||
          currentCursor.firstQuestionIdWhere(questionFilter)
        );
      }
      return currentCursor.firstQuestionIdWhere(questionFilter);
    },
  );
  return createStructuredSelector({
    isLastInActivity: createSelector(
      constant(
        get(dataQuestionnaire, 'answersSheet.activity.patient.answersSheets'),
      ),
      getStaged,
      (answersSheets, staged) => every(
        answersSheets,
        answersSheet => answersSheet.id === answersSheetId ||
            createIsCompleted(staged)(answersSheet),
      ),
    ),
    isStarted: createSelector(
      selectCurrentResponses,
      responses => responses.length > 0,
    ),
    isCompleted: createSelector(
      constant(get(dataQuestionnaire, 'answersSheet')),
      getStaged,
      (answersSheet, staged) => createIsCompleted(staged)(answersSheet),
    ),
    questionnaire: selectQuestionnaire,
    currentQuestionId: selectCurrentQuestionId,
    translations: selectTranslations,
  });
};

// NOTE: This query is intentionally a little bit too broad, but thanks to this
//       it can be used to preload data for Form screen, which is using exactly
//       the same query string.
export const GET_QUESTIONNAIRE = gql`
  query GetQuestionnaire($answersSheetId: ID!) {
    answersSheet(id: $answersSheetId) {
      id
      state
      questionnaire {
        id
        data
        translations {
          id
          language
          languageNativeName
        }
      }
      activity {
        id
        project {
          id
          templates {
            id
            language
          }
        }
        patient {
          id
          answersSheets {
            id
            state
          }
        }
      }
    }
  }
`;

export const GET_TRANSLATION = gql`
  query GetTranslation(
    $answersSheetId: ID!
    $language: String!
    $version: String
  ) {
    answersSheet(id: $answersSheetId) {
      id
      questionnaire {
        id
        translation(language: $language, version: $version) {
          id
          data
        }
      }
    }
  }
`;

export const useQuestionnaireTranslation = (answersSheetId, translationId) => {
  let translationLanguage;
  let translationVersion;
  if (translationId) {
    const chunks = translationId.split('/');
    // eslint-disable-next-line prefer-destructuring
    translationLanguage = chunks[3];
    // eslint-disable-next-line prefer-destructuring
    translationVersion = chunks[4];
  }
  const {
    data,
    loading,
  } = useQuery(GET_TRANSLATION, {
    skip: !translationVersion || !translationLanguage,
    variables: {
      answersSheetId,
      language: translationLanguage,
      version: translationVersion,
    },
  });
  const rawTranslation = get(
    data,
    'answersSheet.questionnaire.translation.data',
  );
  let translation;
  try {
    translation = new QuestionnaireTranslation(JSON.parse(rawTranslation));
  } catch (err) {
    // ignore
  }
  return {
    translation,
    translationLoading: !!loading,
  };
};

const useQuestionnaire = (answersSheetId, translationId) => {
  const {
    data: dataQuestionnaire,
    loading: loadingQuestionnaire,
  } = useQuery(
    GET_QUESTIONNAIRE,
    {
      skip: !answersSheetId,
      variables: {
        answersSheetId,
      },
    },
  );
  let translationLanguage;
  let translationVersion;
  if (translationId) {
    const chunks = translationId.split('/');
    // eslint-disable-next-line prefer-destructuring
    translationLanguage = chunks[3];
    // eslint-disable-next-line prefer-destructuring
    translationVersion = chunks[4];
  }
  const {
    data: dataTranslation,
    loading: loadingTranslation,
  } = useQuery(
    GET_TRANSLATION,
    {
      skip: !translationVersion || !translationLanguage,
      variables: {
        answersSheetId,
        language: translationLanguage,
        version: translationVersion,
      },
    },
  );
  const {
    questionnaire,
    isLastInActivity,
    isStarted,
    isCompleted,
    currentQuestionId,
    translations,
  } = useSelector(
    useMemo(
      () => makeSelector({
        dataQuestionnaire,
        dataTranslation,
        answersSheetId,
      }),
      [
        dataQuestionnaire,
        dataTranslation,
        answersSheetId,
      ],
    ),
  );
  const projectLanguages = useMemo(() => {
    const templates = get(
      dataQuestionnaire,
      'answersSheet.activity.project.templates',
    );
    return uniq(map(templates, 'language'));
  }, [
    dataQuestionnaire,
  ]);
  return {
    loadingQuestionnaire,
    loadingTranslation,
    loading: loadingQuestionnaire || loadingTranslation,
    questionnaire,
    isLastInActivity,
    isStarted,
    isCompleted,
    currentQuestionId,
    translations,
    projectLanguages,
  };
};

export default useQuestionnaire;
