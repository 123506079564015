import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React, {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  isCompleted,
  getStaged,
} from '../../store/stage';
import Stack from '../../common/components/primitives/Stack';
import Sidebar from '../../common/components/primitives/Sidebar';
import Spacer from '../../common/components/primitives/Spacer';
import Text from '../../common/components/base/Text';
import Skeleton from '../../common/components/Skeleton';
import Card from '../../common/components/Card';
import Image from '../../common/components/base/Image';
import AnswersSheetsList from './AnswersSheetsList';
import AnswersSheetButtons from './AnswersSheetButtons';

const getTitle = ({
  t,
  welcomeTitle,
  recipientName,
}) => {
  let title = welcomeTitle || t('welcome');

  if (recipientName) {
    title = `${title} ${recipientName}`;
  }

  return title;
};

const getDescription = ({
  t,
  welcomeMsg,
  answersSheets,
  currentAnswersSheetId,
  currentAnswersSheetState,
}) => {
  if (isEmpty(answersSheets)) {
    return t('jwterror');
  }

  if (!currentAnswersSheetId) {
    return t('completed');
  }

  if (currentAnswersSheetState === 'COMPLETED') {
    return t('completed');
  }

  if (
    currentAnswersSheetState === 'INITIAL' ||
    currentAnswersSheetState === 'SCHEDULED'
  ) {
    return welcomeMsg;
  }

  return t('continued');
};

const Activity = ({
  recipientName,
  milestoneName,
  clinicianName,
  clinicianPhotoUrl,
  projectName,
  projectLogoUrl,
  welcomeTitle,
  welcomeMsg,
  answersSheets,
  currentProjectLanguage,
  currentAnswersSheetId,
  loading,
}) => {
  const {
    t,
  } = useTranslation();
  const currentAnswersSheet = useMemo(() => {
    return find(answersSheets, {
      id: currentAnswersSheetId,
    });
  }, [
    answersSheets,
    currentAnswersSheetId,
  ]);
  const currentAnswersSheetState =
    currentAnswersSheet && currentAnswersSheet.state;
  const staged = useSelector(getStaged);
  const decoratedAnswersSheets = useMemo(
    () => map(answersSheets, (answersSheet) => {
      const {
        id,
        state,
        questionnaire: {
          name,
        },
      } = answersSheet;
      const isCurrent = currentAnswersSheetId && currentAnswersSheetId === id;
      let completionRate;
      if (isCurrent) {
        completionRate = (staged[id] && staged[id].completionRate) || 0;
      }
      return {
        id,
        name,
        state: isCompleted(staged)(answersSheet) ? 'COMPLETED' : state,
        isCurrent,
        completionRate,
      };
    }),
    [
      answersSheets,
      currentAnswersSheetId,
      staged,
    ],
  );
  return (
    <Stack>
      <Spacer>
        <Sidebar
          sidebar={
            projectLogoUrl && (
              <Image
                src={projectLogoUrl}
                alt={projectName || 'Project logo'}
                height="100px"
              />
            )
          }
        >
          <Stack space={0}>
            {/* {clinicianPhotoUrl && (
              <Image
                src={clinicianPhotoUrl}
                alt={clinicianName || 'Clinician photo'}
              />
            )} */}
            <Text.Paragraph importance="high">
              {loading ? (
                <Skeleton width={200} />
              ) : (
                getTitle({
                  t,
                  welcomeTitle,
                  recipientName,
                  currentAnswersSheetState,
                })
              )}
            </Text.Paragraph>
            <Text.Paragraph
              size="small"
              importance="low"
            >
              {loading ? (
                <Skeleton />
              ) : (
                getDescription({
                  t,
                  welcomeMsg,
                  answersSheets,
                  currentAnswersSheetId,
                  currentAnswersSheetState,
                })
              )}
            </Text.Paragraph>
          </Stack>
        </Sidebar>
      </Spacer>
      <Card loading={loading}>
        {currentAnswersSheetId && (
          <Stack>
            {milestoneName && (
              <Stack>
                <Text.Paragraph
                  size="small"
                  importance="low"
                >
                  Milestone
                </Text.Paragraph>
                <Text.Span size="large">{milestoneName}</Text.Span>
              </Stack>
            )}
            <Stack>
              <AnswersSheetButtons
                currentProjectLanguage={currentProjectLanguage}
                answersSheetId={currentAnswersSheetId}
              />
              <AnswersSheetsList answersSheets={decoratedAnswersSheets} />
            </Stack>
          </Stack>
        )}
      </Card>
    </Stack>
  );
};

Activity.propTypes = {
  welcomeTitle: PropTypes.string,
  welcomeMsg: PropTypes.string,
  currentProjectLanguage: PropTypes.string,
  recipientName: PropTypes.string,
  milestoneName: PropTypes.string,
  projectName: PropTypes.string,
  projectLogoUrl: PropTypes.string,
  clinicianName: PropTypes.string,
  clinicianPhotoUrl: PropTypes.string,
  answersSheets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      state: PropTypes.string,
      completionRate: PropTypes.number,
    }),
  ),
  currentAnswersSheetId: PropTypes.string,
  loading: PropTypes.bool,
};

Activity.defaultProps = {
  welcomeTitle: null,
  welcomeMsg: null,
  currentProjectLanguage: null,
  recipientName: null,
  milestoneName: null,
  projectName: null,
  projectLogoUrl: null,
  clinicianName: null,
  clinicianPhotoUrl: null,
  answersSheets: [],
  currentAnswersSheetId: null,
  loading: false,
};

export default Activity;
