import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import isPlainObject from 'lodash/isPlainObject';

// reference: https://www.hl7.org/fhir/datatypes.html
const formats = {
  decimal: {
    re: /^-?(0|[1-9][0-9]*)(\.[0-9]+)?([eE][+-]?[0-9]+)?$/,
    description: 'decimal number',
  },
  instant: {
    // eslint-disable-next-line max-len
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00))$/,
    description: 'timestamp',
  },
  date: {
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1]))?)?$/,
    description: 'date or partial date',
  },
  'date-time': {
    // eslint-disable-next-line max-len
    re: /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])(T([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?(Z|(\+|-)((0[0-9]|1[0-3]):[0-5][0-9]|14:00)))?)?)?$/,
    description: 'date and time of day',
  },
  time: {
    re: /^([01][0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9]|60)(\.[0-9]+)?$/,
    description: 'time of day',
  },
};

function checkSchema(valueSchema, value) {
  if (!isPlainObject(valueSchema)) {
    return {
      message: 'Unrecognized schema specification',
    };
  }
  if (typeof value === 'undefined') {
    return undefined;
  }
  if (typeof valueSchema.type === 'string') {
    switch (valueSchema.type) {
      case 'null': {
        if (value !== null) {
          return {
            message: 'Value is not null',
          };
        }
        break;
      }
      case 'string': {
        if (typeof value !== 'string') {
          return {
            message: 'Value is not string',
          };
        }
        if (valueSchema.format) {
          const format = formats[valueSchema.format];
          if (!format) {
            return {
              message: `Unknown format: ${valueSchema.format}`,
            };
          }
          if (!format.re.test(value)) {
            return {
              message: `Expected ${format.description}`,
            };
          }
        }
        break;
      }
      case 'integer':
      case 'number': {
        if (typeof value !== 'number') {
          return {
            message: 'Value is not number',
          };
        }
        if (valueSchema.type === 'integer' && value % 1 !== 0) {
          // NOTE: at this stage we already know that it's a number
          return {
            message: 'Value is not integer',
          };
        }
        break;
      }
      case 'boolean': {
        if (typeof value !== 'boolean') {
          return {
            message: 'Value is not boolean',
          };
        }
        break;
      }
      case 'object': {
        if (!isPlainObject(value)) {
          return {
            message: 'Value is not an object',
          };
        }
        const errors = {};
        const required = [];
        forEach(valueSchema.required, (key) => {
          required[key] = true;
        });
        forEach(valueSchema.properties, (schemaAtKey, key) => {
          if (has(value, key)) {
            const error = checkSchema(schemaAtKey, value[key]);
            if (error) {
              errors[key] = error;
            }
          } else if (required[key]) {
            errors[key] = {
              message: 'Missing required property',
            };
          }
        });
        if (
          isPlainObject(valueSchema.additionalProperties) ||
          !valueSchema.additionalProperties
        ) {
          forEach(value, (valueAtKey, key) => {
            if (
              typeof valueAtKey !== 'undefined' &&
              !has(valueSchema.properties, key)
            ) {
              if (!valueSchema.additionalProperties) {
                errors[key] = {
                  message: 'Unexpected property',
                };
              } else {
                const error = checkSchema(
                  valueSchema.additionalProperties,
                  valueAtKey,
                );
                if (error) {
                  errors[key] = error;
                }
              }
            }
          });
        }
        if (!isEmpty(errors)) {
          return {
            errors,
          };
        }
        break;
      }
      case 'array': {
        if (!isArray(value)) {
          return {
            message: 'Value is not an array',
          };
        }
        const errors = {};
        forEach(value, (valueAtKey, key) => {
          const error = checkSchema(valueSchema.items, valueAtKey);
          if (error) {
            errors[key] = error;
          }
        });
        if (!isEmpty(errors)) {
          return {
            errors,
          };
        }
        break;
      }
      default: {
        // NOTE: We want support {} representing "any" type
        if (valueSchema.type) {
          return {
            message: `I don't know how to validate type: ${valueSchema.type}`,
          };
        }
      }
    }
  }
  if (isArray(valueSchema.enum)) {
    if (!includes(valueSchema.enum, value)) {
      return {
        message: `Value should be one of: ${valueSchema.enum.join(', ')}`,
      };
    }
  }
  if (has(valueSchema, 'const')) {
    if (!isEqual(valueSchema.const, value)) {
      return {
        message: `Value is not equal to ${valueSchema.const}`,
      };
    }
  }
  if (valueSchema.type === 'string' || valueSchema.type === 'array') {
    if (
      typeof valueSchema.minLength === 'number' &&
      value.length < valueSchema.minLength
    ) {
      return {
        message: `Expected length at least ${valueSchema.minLength}`,
      };
    }
    if (
      typeof valueSchema.maxLength === 'number' &&
      value.length > valueSchema.maxLength
    ) {
      return {
        message: `Expected length at most ${valueSchema.maxLength}`,
      };
    }
  }
  if (has(valueSchema, 'anyOf') && isArray(valueSchema.anyOf)) {
    const n = valueSchema.anyOf.length;
    if (valueSchema.disjointBy) {
      for (let i = 0; i < n; i += 1) {
        if (
          !isPlainObject(valueSchema.anyOf[i]) ||
          !valueSchema.anyOf[i].properties ||
          valueSchema.anyOf[i].type !== 'object'
        ) {
          return {
            message:
              'When disjointBy is used, each alternative must be an object type',
          };
        }
        const schemaToCheck =
          valueSchema.anyOf[i].properties[valueSchema.disjointBy];
        const error = checkSchema(schemaToCheck, value[valueSchema.disjointBy]);
        if (!error) {
          return checkSchema(valueSchema.anyOf[i], value);
        }
      }
    } else {
      for (let i = 0; i < n; i += 1) {
        const schemaToCheck = valueSchema.anyOf[i];
        const error = checkSchema(schemaToCheck, value);
        if (!error) {
          return undefined;
        }
      }
    }
    return {
      message: 'Value does not match any of the specified types',
    };
  }
  return undefined;
}

export default checkSchema;

export function getAllErrors(error) {
  const allErrors = [];
  if (!error) {
    return allErrors;
  }
  if (error.message) {
    allErrors.push({
      message: error.message,
    });
  }
  forEach(error.errors, (nestedError, key) => {
    const allNestedErrors = getAllErrors(nestedError);
    forEach(allNestedErrors, (nested) => {
      allErrors.push({
        key: nested.key ? `${key}.${nested.key}` : key,
        message: nested.message,
      });
    });
  });
  return allErrors;
}

export function getOneError(error) {
  if (!error) {
    return undefined;
  }
  if (error.message) {
    return {
      message: error.message,
    };
  }
  if (isPlainObject(error.errors)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in error.errors) {
      if (has(error.errors, key)) {
        const nested = getOneError(error.errors[key]);
        if (nested && nested.message) {
          return {
            key: nested.key ? `${key}.${nested.key}` : key,
            message: nested.message,
          };
        }
      }
    }
  }
  return undefined;
}

export function getErrorMessage(error) {
  if (!error) {
    return undefined;
  }
  const {
    key,
    message,
  } = getOneError(error);
  if (!message) {
    return undefined;
  }
  if (key) {
    return `${message} at "${key}"`;
  }
  return message;
}

export const isOfType = (valueSchema, value) => !checkSchema(valueSchema, value);
