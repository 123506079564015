import memoize from 'lodash/memoize';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';

/** @module */

const constant = x => () => x;
const identity = x => x;

/**
 * Returns a function that checks if the provided path is a prefix of another path.
 * @param {String} prefix
 * @returns {Function}
 */
export const isPrefix = (prefix, delimiter = '.') => {
  const normalize = delimiter
    ? x => (x[x.length - 1] === delimiter ? x : `${x}${delimiter}`)
    : identity;
  if (!prefix) {
    return constant(true);
  }
  const reference = normalize(prefix);
  const l1 = reference.length;
  return (path) => {
    if (!path) {
      return false;
    }
    const toCheck = normalize(path);
    const l2 = toCheck.length;
    if (l2 < l1) {
      return false;
    }
    for (let i = 0; i < l1; i += 1) {
      if (reference[i] !== toCheck[i]) {
        return false;
      }
    }
    return true;
  };
};

// https://stackoverflow.com/a/9310752/2817257
export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function createFilterOptionFactory({
  propName = 'children',
} = {}) {
  const createFilterOption = predicate => (inputValue, option) => {
    let value;
    if (!inputValue) {
      return true;
    }
    if (typeof option === 'string') {
      value = option;
    } else if (
      option &&
      option.props &&
      typeof option.props[propName] === 'string'
    ) {
      value = option.props[propName];
    }
    if (typeof inputValue !== 'string' || typeof value !== 'string') {
      return false;
    }
    const index = value.toLowerCase().indexOf(inputValue.toLowerCase());
    return predicate(index);
  };
  return memoize(({
    prefixSearchOnly,
  } = {}) => {
    if (prefixSearchOnly) {
      return createFilterOption(index => index === 0);
    }
    return createFilterOption(index => index >= 0);
  });
}

export const template = (source) => {
  if (isNil(source)) {
    return () => '[not_configured]';
  }
  return (variablesOrGetter) => {
    const getVariable =
      typeof variablesOrGetter === 'function'
        ? variablesOrGetter
        : id => variablesOrGetter[id];
    const renderVariable = (id) => {
      const value = getVariable(id);
      if (isNil(value)) {
        return '[unknown]';
      }
      if (typeof value === 'string') {
        return value;
      }
      if (typeof value === 'number') {
        return value.toString();
      }
      return '[unknown]';
    };
    return source.replace(/{{([\s\w]+)}}/g, (_, g1) => {
      const id = trim(g1);
      return renderVariable(id);
    });
  };
};
