import {
  QUESTION_TYPE__NUMBER_WITH_UNIT,
} from '../../../constants';
import Question from '../Question';

export default class QuestionNumber extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__NUMBER_WITH_UNIT;
  }

  shouldAllowDecimalValues() {
    return !!(this.settings && this.settings.allowDecimal);
  }

  getRawKeys() {
    const rawKeys = [
      'value',
    ];
    if (this.useText1()) {
      rawKeys.push('text1');
    }
    if (this.useText2()) {
      rawKeys.push('text2');
    }
    return rawKeys;
  }

  getValueError(value, valueType) {
    const error = super.getValueError(value, valueType);
    if (error) {
      return error;
    }
    if (
      Question.isEmptyAnswer({
        value,
      })
    ) {
      return null;
    }
    if (!this.shouldAllowDecimalValues() && value % 1 !== 0) {
      return 'Expected an integer';
    }
    return null;
  }
}

QuestionNumber.expectedValueType = {
  type: 'number',
};

QuestionNumber.editorSettings = {
  limits: false,
  choices: true,
  snippets: false,
  additional: true,
};

Question.types[QUESTION_TYPE__NUMBER_WITH_UNIT] = QuestionNumber;
