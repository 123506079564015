/* global Meteor */
/* eslint global-require: "off" */
import merge from 'lodash/merge';
import isNaN from 'lodash/isNaN';

let commitSha;
try {
  commitSha = require('./commitSha.build-env').value; // eslint-disable-line
} catch (err) {
  // ...
}

if (!commitSha) {
  try {
    // eslint-disable-next-line
    commitSha = require('../utils/envLoader!./commitSha.build-env').value;
  } catch (err) {
    // ...
  }
}

let commitTime;
try {
  commitTime = require('./commitTime.build-env').value; // eslint-disable-line
} catch (err) {
  // ...
}

if (!commitTime) {
  try {
    // eslint-disable-next-line
    commitTime = require('../utils/envLoader!./commitTime.build-env').value;
  } catch (err) {
    // ...
  }
}

if (commitTime) {
  const unix = +commitTime;
  if (!isNaN(unix)) {
    commitTime = unix;
  } else {
    commitTime = null;
  }
}

let branchName;
try {
  branchName = require('./branchName.build-env').value; // eslint-disable-line
} catch (err) {
  // ...
}

if (!branchName) {
  try {
    // eslint-disable-next-line
    branchName = require('../utils/envLoader!./branchName.build-env').value;
  } catch (err) {
    // ...
  }
}

let meteorSettingsOverwrite;
try {
  meteorSettingsOverwrite = require('./meteorSettingsOverwrite.build-env')
    .value; // eslint-disable-line
} catch (err) {
  // ...
}

const settings = {
  public: {
    commitSha,
    commitTime,
    branchName,
  },
};

const parseSettings = (string) => {
  if (!string) {
    // it's not an error, settings were simply not provided
    return;
  }
  let parsedSettings;
  try {
    parsedSettings = JSON.parse(string);
  } catch (err) {
    throw new Error(`Settings string is not a valid JSON, got: ${string}`);
  }
  if (parsedSettings) {
    merge(settings, parsedSettings);
  }
};

if (typeof Meteor !== 'undefined') {
  // We still need this for Meteor clients.
  merge(settings, Meteor.settings);
} else {
  // This mimics the typical Meteor behavior.
  parseSettings(process.env.METEOR_SETTINGS);
}

// Can use it to overwrite part of the settings object in individual services, e.g. logs.defaultLevel.
if (process.env.METEOR_SETTINGS_LOCAL) {
  parseSettings(process.env.METEOR_SETTINGS_LOCAL);
}

// These settings will usually be provided at build stage. In case of react apps,
// it's quite important to ensure that proper commitSha is there because the one
// which is imported from `./commitSha.build-env` will not be right unless we
// use a custom webpack loader.
if (process.env.REACT_APP_SETTINGS) {
  parseSettings(process.env.REACT_APP_SETTINGS);
}

// Same as METEOR_SETTINGS_LOCAL, but useful with react apps
if (process.env.REACT_APP_SETTINGS_LOCAL) {
  parseSettings(process.env.REACT_APP_SETTINGS_LOCAL);
}

// These settings are deployment specific and should be injected during
// the front-end server startup.
if (process.env.NODE_ENV === 'production') {
  const settingsString = 'PRODUCTION_SETTINGS_PLACEHOLDER'; // eslint-disable-line
  if (typeof settingsString !== 'string') {
    throw new Error(
      'Production settings should be encoded in a form of json string',
    );
  } else if (
    settingsString.toLowerCase() !== 'production_settings_placeholder'
  ) {
    // NOTE: Checking against lowercase version to reduce the risk of injection happening twice
    parseSettings(settingsString);
  }
}

if (meteorSettingsOverwrite) {
  parseSettings(meteorSettingsOverwrite);
}

// force utc, unless explicitly overwritten
if (settings.private && !settings.private.defaultTimezone) {
  settings.private.defaultTimezone = 'utc';
}

export default settings;
