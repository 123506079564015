import React from 'react';
import PropTypes from 'prop-types';
import Zedoc from '../../common/components/Zedoc';
import Cluster from '../../common/components/primitives/Cluster';
import Settings from '../../components/Settings';

const Header = ({
  loading,
}) => (
  <Cluster
    align="center"
    overflow="initial"
    space={0}
  >
    <Zedoc />
    <Settings
      loading={loading}
      onTop
    />
  </Cluster>
);

Header.propTypes = {
  loading: PropTypes.bool,
};

Header.defaultProps = {
  loading: false,
};

export default Header;
