import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import Formula from '../Formula';
import {
  FORMULA_TYPE__ANSWER_NUMERIC_VALUE,
} from '../../../constants';
import {
  parseValueExpr,
} from '../../../utils/question';

// eslint-disable-next-line import/prefer-default-export
export class FormulaAnswerNumericValue extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.id) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {
        id: this.settings.id,
      },
    };
    const question = questionsHierarchy.getQuestionById(this.settings.id);
    const possibleOutcomes = question
      ? question
        .getChoices()
        .map(({
          value,
          valueExpr,
          label,
        }) => {
          if (question.shouldUseCustomNumericValue() && !isEmpty(valueExpr)) {
            return {
              label,
              value: parseValueExpr(valueExpr),
            };
          }
          return {
            label,
            value: parseValueExpr(value),
          };
        })
        .filter(({
          value,
        }) => !isNaN(value))
      : [];
    if (possibleOutcomes.length > 0) {
      compiled.meta = {
        ...compiled.meta,
        possibleOutcomes,
      };
    }
    return compiled;
  }

  evaluate(scope) {
    const data = scope.lookupAnswer(this.settings.id);
    if (data && data.error) {
      return {
        error: data.error,
      };
    }
    if (!data) {
      return {
        error: this.constructor.NoData,
      };
    }
    return scope.evaluateAsNumber(data.value, this.settings.id);
  }

  toMongoExpression() {
    return {
      $reduce: {
        input: '$responses',
        initialValue: null,
        in: {
          $cond: {
            if: {
              $eq: [
                '$$this.questionId',
                {
                  $literal: this.settings.id,
                },
              ],
            },
            then: {
              $ifNull: [
                '$$this.meta.number',
                'N/A',
              ],
            },
            else: '$$value',
          },
        },
      },
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'id':
          return mapQuestionId(value);
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__ANSWER_NUMERIC_VALUE] = FormulaAnswerNumericValue;
