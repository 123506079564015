import BaseModel from './BaseModel';

class ProjectVariable extends BaseModel {
  constructor(doc, project) {
    super(doc);
    Object.defineProperty(this, 'project', {
      value: project,
    });
  }

  getLabel() {
    return this.label;
  }

  isProfileFormVariable() {
    return !!this.useInProfileForm;
  }

  isReadOnly() {
    return !!this.readOnly;
  }

  isSorted() {
    return !!this.sorted;
  }

  getSortingFunction() {
    if (this.valueRanges) {
      const ordering = {};
      this.valueRanges.forEach(({
        value,
      }, index) => {
        ordering[value] = index;
      });
      return ({
        value,
      }) => ordering[value];
    }
    return ({
      value,
    }) => value;
  }
}

export default ProjectVariable;
