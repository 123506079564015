import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../common/components/Icon';
import Divider from '../../common/components/Divider';
import Text from '../../common/components/base/Text';
import Stack from '../../common/components/primitives/Stack';
import Cluster from '../../common/components/primitives/Cluster';
import Spacer from '../../common/components/primitives/Spacer';

const getIcon = (state) => {
  if (state === 'COMPLETED') {
    return 'check';
  }
  return 'lock';
};

const AnswersSheetsList = ({
  answersSheets,
}) => (
  <Spacer>
    <Stack space={2}>
      {answersSheets.map(({
        id,
        name,
        state,
        completionRate,
        isCurrent,
      }) => (
        <Stack
          key={id}
          space={2}
        >
          {' '}
          <Divider />
          <Cluster justify="space-between">
            <Text.Span
              size="small"
              importance={isCurrent ? 'high' : 'low'}
            >
              {name}
            </Text.Span>
            <Text.Span
              size="small"
              importance={isCurrent ? 'high' : 'low'}
            >
              {isCurrent ? (
                `${completionRate}%`
              ) : (
                <Icon type={getIcon(state)} />
              )}
            </Text.Span>
          </Cluster>
        </Stack>
      ))}
    </Stack>
  </Spacer>
);

AnswersSheetsList.propTypes = {
  answersSheets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
      isCurrent: PropTypes.bool,
      completionRate: PropTypes.number,
    }),
  ),
};

AnswersSheetsList.defaultProps = {
  answersSheets: [],
};

export default AnswersSheetsList;
