import {
  connectRouter,
} from 'connected-react-router';
import {
  reducer as storageReducer,
} from '../common/utilsClient/redux/storage';
import {
  clockReducer,
} from '../common/utils/clock';
import {
  createMultiReducer,
} from '../common/utilsClient/multiReducer';
import {
  reducer as questionnaire,
} from '../common/containers/Questionnaire';
import {
  reducer as stageReducer,
} from './stage';

export const createReducer = (history, onNewReducer) => createMultiReducer({
  onNewReducer,
  useRegisteredStores: true,
  sections: {
    router: connectRouter(history),
    stage: stageReducer,
    storage: storageReducer,
    clock: clockReducer,
    questionnaire,
  },
});
