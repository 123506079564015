import React, {
  lazy,
  Suspense,
} from 'react';
import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';
import {
  createStructuredSelector,
} from 'reselect';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  ConnectedRouter,
} from 'connected-react-router';
import NotFound from '../common/components/NotFound';
import Loading from '../common/components/Loading';
import Cover from '../common/components/primitives/Cover';
import TransitionRoute from '../common/components/transitions/TransitionRoute';
import LandingSkeleton from '../screens/Landing/LandingSkeleton';
import GlobalStyle from '../GlobalStyle';
import {
  getAnimationSpeed,
} from '../store/preferences';

const Landing = lazy(() => import('../screens/Landing'));
const Form = lazy(() => import('../screens/Form'));
const ThankYou = lazy(() => import('../screens/ThankYou'));
const ContactUs = lazy(() => import('../screens/ContactUs'));
const FAQ = lazy(() => import('../screens/FAQ'));
const FormBuilder = lazy(() => import('../screens/FormBuilder'));

const getKey = pathname => pathname.split('/')[1];

const speedFactor = 1;

const withLandingFallback = (Component) => {
  return props => (
    <Suspense fallback={<LandingSkeleton />}>
      <Component {...props} />
    </Suspense>
  );
};

const Routes = connect(() => createStructuredSelector({
  location: state => state.router.location,
  animationSpeed: getAnimationSpeed,
}))(({
  location,
  animationSpeed,
}) => (
  <TransitionRoute
    id={getKey(location.pathname)}
    duration={(animationSpeed * speedFactor) / 1000}
  >
    <Cover
      space={0}
      centeredSelector="main"
      isScrollable
    >
      <GlobalStyle />
      <Suspense fallback={<Loading />}>
        <Switch location={location}>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/activities/" />}
          />
          <Route
            path="/activities/"
            exact
            component={withLandingFallback(Landing)}
          />
          <Route
            path="/forms/:answersSheetId/:questionId?"
            component={Form}
          />
          <Route
            path="/thank-you/:answersSheetId?"
            component={ThankYou}
          />
          <Route
            path="/faq"
            exact
            component={FAQ}
          />
          <Route
            path="/contact-us"
            exact
            component={ContactUs}
          />
          <Route
            exact
            path="/form-builder"
            component={FormBuilder}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Cover>
  </TransitionRoute>
));

const Router = ({
  history,
}) => (
  <ConnectedRouter history={history}>
    <Routes />
  </ConnectedRouter>
);

Router.propTypes = {
  history: PropTypes.object.isRequired, //  eslint-disable-line react/forbid-prop-types
};

export default Router;
