import {
  css,
} from 'styled-components';
import {
  theme,
} from '../../utilsClient/cssHelpers';

export const reset = css`
  background: none;
  border: none;
  outline: none;
`;

export const overlay = css`
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 200%;
    top: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
      rgba(255, 255, 255, 0.05) 50%,
      transparent 50%,
      rgba(0, 0, 0, 0.05) 50%
    );
    opacity: 0;
    will-change: opacity, transform;
  }

  &:focus-visible::before,
  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    opacity: 1;
    transform: translateY(-50%);
  }
`;

export const types = {
  default: css`
    background-color: ${theme('color.surface')};
    border-color: ${theme('color.border')};
    color: ${theme('color.onSurface')};

    &::before {
      background: linear-gradient(
        rgba(0, 0, 0, 0.02) 50%,
        transparent 50%,
        rgba(0, 0, 0, 0.04) 50%
      );
    }
  `,
  primary: css`
    background-color: ${theme('color.primary')};
    color: ${theme('color.onPrimary')};
  `,
  link: css`
    background-color: transparent !important;
    border-color: transparent !important;
    color: ${theme('color.primary')};

    &::before {
      background: linear-gradient(
        rgba(0, 0, 0, 0.02) 50%,
        transparent 50%,
        rgba(0, 0, 0, 0.04) 50%
      );
    }
  `,
  danger: css`
    background-color: ${theme('color.danger')};
    color: ${theme('color.onPrimary')};
  `,
  cta: css`
    background-color: ${theme('color.primary')};
    border-radius: 20px;
    color: ${theme('color.onPrimary')};
  `,
};

export const sizes = {
  small: css`
    padding: 0 ${theme('space.2')};
    /* To account for 24px height */
    line-height: 22px;

    svg {
      height: 1em;
      width: 1em;
    }
  `,
  default: css`
    padding: 0.1875em ${theme('space.2')};

    svg {
      height: 1em;
      width: 1em;
    }
  `,
  large: css`
    padding: 0.4375em ${theme('space.3')};

    svg {
      height: 1.375em;
      width: 1.375em;
    }
  `,
};

const iconOnlySizes = {
  small: css`
    height: 1.5em;
    padding-right: 0.1875em;
    padding-left: 0.1875em;
  `,
  default: css`
    height: 2em;
    padding-right: 0.4375em;
    padding-left: 0.4375em;

    .anticon {
      padding: 2px;
    }
  `,
  large: css`
    height: 2.5em;
    padding-right: 8px;
    padding-left: 8px;

    /* .anticon {
      margin: -3px 0;
    } */
  `,
};

export const ghost = {
  default: css`
    background: none;
    border: none;
  `,
  primary: css`
    background: none;
    color: ${theme('color.primary')};
  `,
  danger: css`
    background: none;
    color: ${theme('color.danger')};
  `,
};

export const disabled = css`
  background-color: ${theme('color.background')};
  border-color: ${theme('color.border')};
  color: ${theme('color.disabled')};
  cursor: not-allowed;

  &::before,
  &::after {
    content: none;
  }
`;

export const loading = css`
  background-color: ${theme('color.background')};
  border-color: ${theme('color.border')};
  color: ${theme('color.disabled')};
  cursor: default;

  /* &::before {
    background-color: rgba(255,255,255,0.1);
  } */

  &::before,
  &::after {
    content: none;
  }
`;

export const block = css`
  width: 100%;
`;

export const iconOnly = css`
  display: flex;
  padding-right: 0.3125em;
  padding-left: 0.3125em;
  border-radius: 50%;

  ${props => iconOnlySizes[props.size]};
`;

export const ripple = css`
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active::after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
`;

export const defaultStyles = css`
  cursor: pointer;
  display: block;
  position: relative;
  /* border: 0; */
  border: 1px solid hsla(${theme('color.darkBase')}, 0.1);
  transition: 150ms ease-in-out;

  &:hover {
    transform: translate3d(0px, -2px, 0px);
  }

  &:active {
    transform: translate3d(0, 0px, 0);
  }

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(1.05) scaleY(1.2);
  }
`;
