import isEmpty from 'lodash/isEmpty';
import {
  createQuestionnaireMiddleware,
} from '../common/containers/Questionnaire';
import {
  toFormValues,
  toResponsesArray,
} from '../common/utils/responses';
import {
  load,
  save,
} from './stage';
import getCompletionRate from '../utils/getCompletionRate';

// eslint-disable-next-line import/prefer-default-export
export const createMiddleware = () => createQuestionnaireMiddleware({
  autosaveDelay: 200,
  getInitialValues: ({
    answersSheetId,
    getState,
  }) => {
    const state = getState();
    const rawAnswersSheet = load(answersSheetId)(state);
    if (rawAnswersSheet && !isEmpty(rawAnswersSheet.responses)) {
      return toFormValues(rawAnswersSheet.responses);
    }
    // TODO: Otherwise fetch values from server and save them in local store
    return {};
  },
  handleAutosave: ({
    answersSheetId,
    questionnaire,
    formValues,
    dispatch,
    dynamicProperties,
  }) => {
    dispatch(
      save(answersSheetId, {
        responses: toResponsesArray(formValues),
        completionRate: getCompletionRate({
          formValues,
          dynamicProperties,
          questionnaire,
        }),
      }),
    );
  },
});
