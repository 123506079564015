import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../primitives/Stack';
import Grid from '../primitives/Grid';
import Cluster from '../primitives/Cluster';
import Markdown from '../Markdown';
import Card from './Card';
import {
  theme,
} from '../../utilsClient/cssHelpers';

// TODO: Add back support for icons and images

// {icon && (
//   <StyledIcon>
//     <img
//       // eslint-disable-next-line global-require, import/no-dynamic-require
//       src={require(`../../../common/assets/icons/${icon}.svg`)}
//       alt={icon}
//     />
//   </StyledIcon>
// )}

// {!!imageUrl && (
//   <PosedContainer>
//     <StyledImg
//       src={imageUrl}
//       alt={prefix}
//     />
//   </PosedContainer>
// )}

const WithSideLine = styled.div`
  position: relative;

  ol,
  ul,
  dl {
    padding-left: ${theme('space.3')};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(-${theme('space.3')});
    border-top-right-radius: ${theme('border.radius.2')};
    border-bottom-right-radius: ${theme('border.radius.2')};
    width: 3px;
    height: 100%;

    ${props => (props.isSuccess
    ? `
      background-color: ${theme('color.success')(props)};
    `
    : `
      background-color: ${theme('color.primary')(props)};
    `)}
  }
`;

const CardQuestionLayout = ({
  extra,
  children,
}) => {
  return extra ? (
    <Grid align="flex-start">{children}</Grid>
  ) : (
    <Stack>{children}</Stack>
  );
};

const CardQuestion = ({
  title,
  description,
  extra,
  children,
  isSuccess,
}) => {
  return (
    <Card>
      <CardQuestionLayout extra={extra}>
        <Stack>
          <WithSideLine isSuccess={isSuccess}>
            {title && (
              <Markdown
                importance="high"
                source={title}
                rendererMethods={{
                  list: (body, ordered, number) => `<p>${number}. ${body}</p>`,
                  listitem: text => text,
                }}
                whitelist={[
                  'list',
                  'listitem',
                  'paragraph',
                  'strong',
                  'em',
                ]}
              />
            )}
            {description && (
            <Markdown
              importance="low"
              source={description}
            />
            )}
          </WithSideLine>
          {extra}
        </Stack>
        {extra ? <Cluster justify="center">{children}</Cluster> : children}
      </CardQuestionLayout>
    </Card>
  );
};

CardQuestion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  extra: PropTypes.node,
  isSuccess: PropTypes.bool,
};

CardQuestion.defaultProps = {
  title: null,
  description: null,
  extra: null,
  isSuccess: false,
};

export default CardQuestion;
