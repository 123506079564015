import map from 'lodash/map';
import reduce from 'lodash/reduce';
import Formula from '../Formula';
import {
  FORMULA_TYPE__SUM_NUMERIC_VALUES,
} from '../../../constants';
import Schema from '../../../utils/Schema';

const settingsSchema = new Schema(
  {
    summands: [
      new Schema({
        id: {
          type: String,
        },
        weight: {
          type: Number,
          optional: true,
        },
      }),
    ],
  },
  {
    additionalProperties: true,
  },
);

// eslint-disable-next-line import/prefer-default-export
export class FormulaSumNumericValues extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (settingsSchema.getErrors(this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    return {
      value: reduce(
        this.settings.summands,
        (total, {
          id,
          weight = 1,
        }) => {
          const answers = scope.pickAllAnswers(id);
          return (
            total +
            reduce(
              answers,
              (currentValue, answer) => {
                if (answer) {
                  const result = scope.evaluateAsNumber(answer.value, id);
                  if (!result.error) {
                    return currentValue + result.value * weight;
                  }
                }
                return currentValue;
              },
              0,
            )
          );
        },
        0,
      ),
    };
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {},
    };
    if (this.meta && this.meta.sectionId) {
      compiled.settings = {
        ...compiled.settings,
        summands: questionsHierarchy.mapQuestions(
          q => ({
            id: q.id,
          }),
          {
            sectionId: this.meta.sectionId,
          },
        ),
      };
    } else if (this.meta && this.meta.questionIds) {
      compiled.settings = {
        ...compiled.settings,
        summands: map(this.meta.questionIds, questionId => ({
          id: questionId,
        })),
      };
    }
    return compiled;
  }

  toMongoExpression() {
    return {
      $reduce: {
        input: '$responses',
        initialValue: 0,
        in: this.constructor.createAggregationExpression(
          this.settings.summands,
        ),
      },
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'summands':
          return map(value, summand => ({
            ...summand,
            id: mapQuestionId(summand.id),
          }));
        default:
          return value;
      }
    };
  }

  static createMapMeta(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'sectionId':
          return mapQuestionId(value);
        case 'questionIds':
          return map(value, mapQuestionId);
        default:
          return value;
      }
    };
  }

  static createAggregationExpression(summands) {
    if (!summands || !summands[0]) {
      return '$$value';
    }
    return {
      $cond: {
        if: {
          $eq: [
            '$$this.questionId',
            {
              $literal: summands[0].id,
            },
          ],
        },
        then: {
          $add: [
            '$$value',
            {
              $multiply: [
                {
                  $ifNull: [
                    '$$this.meta.number',
                    0,
                  ],
                },
                {
                  $literal:
                    typeof summands[0].weight === 'number'
                      ? summands[0].weight
                      : 1,
                },
              ],
            },
          ],
        },
        else: this.createAggregationExpression(summands.slice(1)),
      },
    };
  }
}

Formula.types[FORMULA_TYPE__SUM_NUMERIC_VALUES] = FormulaSumNumericValues;
