export const levels = {
  alert: 0, // requires immediate action, otherwise system unstable
  critical: 1, // probably a serious problem, needs investigation
  error: 2, // error, requires attention but not urgent
  warn: 3, // not an error but something is not good
  info: 4, // default operational level
  verbose: 5,
  debug: 6,
};

export const valueToLevel = {
  0: 'alert',
  1: 'critical',
  2: 'error',
  3: 'warn',
  4: 'info',
  5: 'verbose',
  6: 'debug',
};
