import isEmpty from 'lodash/isEmpty';
import EvaluationScope from '../../models/EvaluationScope';
import getFormErrors from './getFormErrors';
import evaluateFormValuesAndProperties from './evaluateFormValuesAndProperties';
import {
  ANSWERS_SHEET_NAVIGATION_TYPE__CAT,
} from '../../constants';
import {
  toFormValues,
} from '../responses';

const validateQuestionnaire = (
  questionnaire,
  answersSheet,
  {
    scopeOptions,
  },
) => {
  const evaluationScope = new EvaluationScope({
    ...scopeOptions,
    questionnaire,
    answers: answersSheet.toFormValues(),
    answersSheet: answersSheet.getFormulaScopeProperties(),
  });

  let propertiesOverrides;
  if (answersSheet.getNavigationType() === ANSWERS_SHEET_NAVIGATION_TYPE__CAT) {
    // NOTE: Force all questions to be optional, except those which were already answered.
    propertiesOverrides = toFormValues(answersSheet.responses, {
      pickValues: () => ({
        optional: false,
      }),
    });
    propertiesOverrides['*'] = {
      optional: true,
    };
  }

  const {
    formValues,
    properties,
  } = evaluateFormValuesAndProperties(
    evaluationScope,
    {
      propertiesOverrides,
    },
  );

  const formErrors = getFormErrors(questionnaire, formValues, {
    properties,
    skipHidden: true,
  });

  if (!isEmpty(formErrors)) {
    return formErrors;
  }

  return null;
};

export default validateQuestionnaire;
