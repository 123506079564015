import Button from './Button';
import Group from './ButtonGroup';
import {
  defaultStyles,
} from './styles';

Button.Group = Group;

export default Button;

export {
  Group,
  defaultStyles,
};
