import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../../utilsClient/cssHelpers';

const SidebarDefault = styled.div`
  > * {
    display: flex;
    flex-wrap: wrap;
    margin: ${props => `calc(${theme(`space.${props.space}`)(props)} / 2 * -1)`};

    ${props => props.align && `align-items: ${props.align}`};
  }

  > * > * {
    flex-grow: 1;

    &:not(:empty) {
      margin: ${props => `calc(${theme(`space.${props.space}`)(props)} / 2)`};
    }
  }

  ${props => `
    > * > :${props.side === 'left' ? 'last' : 'first'}-child {
      flex-basis: 0;
      flex-grow: 999;
      min-width: calc(50% - ${theme(`space.${props.space}`)(props)});
    }
  `}
`;

const Sidebar = ({
  children,
  sidebar,
  side,
  space,
  align,
}) => (
  <SidebarDefault
    side={side}
    space={space}
    align={align}
  >
    <div>
      {side === 'left' && sidebar}
      {children}
      {side === 'right' && sidebar}
    </div>
  </SidebarDefault>
);

Sidebar.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.node,
  side: PropTypes.oneOf([
    'left',
    'right',
  ]),
  space: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  align: PropTypes.string,
};

Sidebar.defaultProps = {
  children: <div />,
  sidebar: null,
  side: 'right',
  space: 3,
  align: 'center',
};

export default Sidebar;
