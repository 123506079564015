import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import Center from '../primitives/Center';

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  z-index: 1;

  &:not(:empty) {
    padding: ${theme('space.3')};

    @media (max-width: 500px) {
      padding-top: ${theme('space.2')};
      padding-bottom: ${theme('space.2')};
    }
  }

  /* body:not(.has-background) & {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: ${theme('rhythm.small')};
      pointer-events: none;
      z-index: 2;
      transform: translateZ(0);
    }

    &::before {
      /* Should match background color set in theme.js */
      /* background: linear-gradient(0deg, hsla(var(--hue), 42%, 94%, 1), hsla(var(--hue), 42%, 94%, 0)); */
      /* top: -${theme('rhythm.small')}; */
    }
  } */
`;

const Footer = ({
  children,
}) => (children ? (
  <StyledFooter role="contentinfo">
    <Center
      max={5}
      isStretched
    >
      {children}
    </Center>
  </StyledFooter>
) : null);

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = {
  children: null,
};

export default Footer;
