import React from 'react';
import PropTypes from 'prop-types';
import ApolloClient from 'apollo-client';
import {
  ApolloProvider,
} from 'react-apollo';
import {
  Provider,
} from 'react-redux';
import Router from '../routes/Router';
import ThemeProvider from './ThemeProvider';
import AppSkeleton from './AppSkeleton';
import useLoading from './useLoading';

const App = ({
  store,
  history,
  client,
  promise,
}) => {
  const isLoading = useLoading(promise);

  if (isLoading) {
    return (
      <Provider store={store}>
        <ThemeProvider>
          <AppSkeleton />
        </ThemeProvider>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider>
          <Router history={history} />
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  promise: PropTypes.instanceOf(Promise),
  client: PropTypes.instanceOf(ApolloClient).isRequired,
};

App.defaultProps = {
  promise: null,
};

export default App;
