export const CREATE_USERS = 'collections.Users.manage.create';
export const MODIFY_USERS = 'collections.Users.manage.modify';
export const DELETE_USERS = 'collections.Users.manage.delete';
export const BROWSE_USERS = 'collections.Users.manage.browse';

export const CREATE_PROJECTS = 'collections.Projects.manage.create';
export const MODIFY_PROJECTS = 'collections.Projects.manage.modify';
export const DELETE_PROJECTS = 'collections.Projects.manage.delete';
export const BROWSE_PROJECTS = 'collections.Projects.manage.browse';

// NOTE: Questionnaires are now managed via repo; modify actually means
//       fetch newer version from the repository.
export const MODIFY_QUESTIONNAIRES = 'collections.Questionnaires.manage.modify';
export const BROWSE_QUESTIONNAIRES = 'collections.Questionnaires.manage.browse';

export const BROWSE_AUDIT_LOGS = 'collections.AuditLogs.manage.browse';

export const CREATE_ANSWERS_SHEETS = 'collections.AnswersSheets.manage.create';
export const MODIFY_ANSWERS_SHEETS = 'collections.AnswersSheets.manage.modify';
export const DELETE_ANSWERS_SHEETS = 'collections.AnswersSheets.manage.delete';
export const BROWSE_ANSWERS_SHEETS = 'collections.AnswersSheets.manage.browse';

export const CREATE_RECIPIENTS = 'collections.Recipients.manage.create';
export const MODIFY_RECIPIENTS = 'collections.Recipients.manage.modify';
export const DELETE_RECIPIENTS = 'collections.Recipients.manage.delete';
export const BROWSE_RECIPIENTS = 'collections.Recipients.manage.browse';

export const BROWSE_ROLES = 'collections.Roles.manage.browse';
export const CREATE_ROLES = 'collections.Roles.manage.create';
export const MODIFY_ROLES = 'collections.Roles.manage.modify';
export const DELETE_ROLES = 'collections.Roles.manage.delete';

export const BROWSE_USERS_GROUPS = 'collections.UsersGroups.manage.browse';
export const CREATE_USERS_GROUPS = 'collections.UsersGroups.manage.create';
export const MODIFY_USERS_GROUPS = 'collections.UsersGroups.manage.modify';
export const DELETE_USERS_GROUPS = 'collections.UsersGroups.manage.delete';

export const BROWSE_FORM_BUILDER_PROJECTS =
  'collections.FormBuilderProjects.manage.browse';
export const CREATE_FORM_BUILDER_PROJECTS =
  'collections.FormBuilderProjects.manage.create';
export const MODIFY_FORM_BUILDER_PROJECTS =
  'collections.FormBuilderProjects.manage.modify';
export const DELETE_FORM_BUILDER_PROJECTS =
  'collections.FormBuilderProjects.manage.delete';

export const ADJUST_RECIPIENT_MILESTONE = 'adjust.recipient.milestone';

export const SEND_NOTIFICATIONS_TO_PATIENTS =
  'actions.sendNotificationsToPatients';

export const BROWSE_REPORTS = 'reports.manage.browse';
export const USE_SUPERPOWERS = 'superpowers.use';
export const ACCESS_RECIPIENT_PARTICULARS =
  'models.Recipient.fields.particulars';
export const IMPERSONATE_RECIPIENT = 'actions.impersonateRecipient';
export const PERFORM_DEVOPS_TASKS = 'actions.performDevOpsTasks';
export const EXPORT_PATIENTS_DATA = 'actions.exportPatientsData';
export const IMPORT_PATIENTS_INTO_PROJECT = 'actions.importPatientsIntoProject';
export const EXPAND_VALUE_SETS = 'actions.ValueSets.expand';
