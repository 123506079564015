import {
  isEmptyAnswer,
} from '../../../utils/question';
import Formula from '../Formula';
import {
  FORMULA_TYPE__ANSWER_VALUE,
} from '../../../constants';

export class FormulaAnswerValue extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.id) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {
        id: this.settings.id,
      },
    };
    if (this.settings.field) {
      compiled.settings.field = this.settings.field;
    }
    if (
      compiled.settings.field !== undefined &&
      compiled.settings.field !== 'value'
    ) {
      return compiled;
    }
    const question = questionsHierarchy.getQuestionById(this.settings.id);
    const possibleOutcomes = question
      ? question.getChoices().map(({
        value,
        label,
      }) => ({
        value,
        label,
      }))
      : [];
    if (possibleOutcomes.length > 0) {
      compiled.meta = {
        ...compiled.meta,
        possibleOutcomes,
      };
    }
    return compiled;
  }

  evaluate(scope) {
    const {
      id,
      field = 'value',
    } = this.settings;
    const data = scope.lookupAnswer(id);
    if (data && data.error) {
      return {
        error: data.error,
      };
    }
    if (isEmptyAnswer(data)) {
      return {
        error: this.constructor.NoData,
      };
    }
    if (field) {
      return {
        value: data[field],
      };
    }
    return {
      value: data,
    };
  }

  toMongoExpression() {
    return this.constructor.createMongoExpression(
      this.settings.id,
      this.settings.field,
    );
  }

  static createMongoExpression(questionId, field = 'value') {
    return {
      $reduce: {
        input: '$responses',
        initialValue: null,
        in: {
          $cond: {
            if: {
              $eq: [
                '$$this.questionId',
                {
                  $literal: questionId,
                },
              ],
            },
            then: field ? `$$this.answer.${field}` : '$$this.answer',
            else: '$$value',
          },
        },
      },
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'id':
          return mapQuestionId(value);
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__ANSWER_VALUE] = FormulaAnswerValue;
