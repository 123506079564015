import styled from 'styled-components';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import notFound from '../assets/not-found.svg';
import {
  theme,
} from '../utilsClient/cssHelpers';

const StyledLink = styled(Link)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    margin: ${theme('padding.xl')}px;
    max-width: ${theme('screen.xs')}px;
  }
`;

const NotFound = () => (
  <StyledLink to="/">
    <img
      src={notFound}
      alt="404 Not Found"
    />
  </StyledLink>
);

export default NotFound;
