import PropTypes from 'prop-types';
import {
  createSelector,
} from 'reselect';
import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  compose,
} from 'recompose';
import Context, {
  withContext,
} from './QuestionnaireContext';
import SelectorsHub from './SelectorsHub';
import {
  argument,
} from '../../utilsClient/selectors';

class Element extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getContext = createSelector(
      argument(0, 'context'),
      argument(0, 'id'),
      (context, elementId) => {
        if (context) {
          return context.createSubContext(elementId);
        }
        return null;
      },
    );
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemove() {
    const {
      id,
      dispatch,
      context,
    } = this.props;
    dispatch(context.removeElement(id));
  }

  render() {
    const {
      children,
      ...props
    } = this.props;
    if (typeof children === 'function') {
      return (
        <Context.Provider value={this.getContext(props)}>
          {children({
            handleRemove: this.handleRemove,
          })}
        </Context.Provider>
      );
    }
    return (
      <Context.Provider value={this.getContext(props)}>
        {children}
      </Context.Provider>
    );
  }
}

Element.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  dispatch: PropTypes.func.isRequired,
  context: PropTypes.instanceOf(SelectorsHub).isRequired,
};

Element.defaultProps = {
  children: null,
};

export default compose(
  withContext(),
  connect(
    null,
    null,
  ),
)(Element);
