import {
  QUESTION_TYPE__SCALE,
} from '../../../constants';
import Question from '../Question';

export default class QuestionScale extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SCALE;
  }

  getRawKeys() {
    const rawKeys = [
      'value',
    ];
    if (this.useOther()) {
      rawKeys.push('other');
    }
    if (this.useText1()) {
      rawKeys.push('text1');
    }
    if (this.useText2()) {
      rawKeys.push('text2');
    }
    return rawKeys;
  }

  getRandomAnswer() {
    const value =
      this.settings.minValue +
      Math.round(
        Math.random() * (this.settings.maxValue - this.settings.minValue),
      );
    return {
      value,
    };
  }
}

QuestionScale.expectedValueType = {
  type: 'integer',
};

QuestionScale.editorSettings = {
  limits: true,
  choices: false,
  snippets: false,
  additional: true,
};

Question.types[QUESTION_TYPE__SCALE] = QuestionScale;
