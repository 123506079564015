// ----------------------------------------------
// --- PROPERTIES USED TO IDENTIFY RECIPIENTS ---
// ----------------------------------------------
export const RECIPIENT_PROPERTY__IDENTIFIER = 'identifier';
export const RECIPIENT_PROPERTY__EMAIL = 'email';
export const RECIPIENT_PROPERTY__NAME = 'name';
export const RECIPIENT_PROPERTY__PHONE = 'phone';
export const RECIPIENT_PROPERTY__NATIONAL_ID = 'nationalId';
export const RECIPIENT_PROPERTIES = [
  RECIPIENT_PROPERTY__IDENTIFIER,
  RECIPIENT_PROPERTY__EMAIL,
  RECIPIENT_PROPERTY__NAME,
  RECIPIENT_PROPERTY__PHONE,
  RECIPIENT_PROPERTY__NATIONAL_ID,
];

export const RECIPIENT_STATUS__INITIAL = 'Initial';
export const RECIPIENT_STATUS__REJECTED = 'Rejected';
export const RECIPIENT_STATUS__ACTIVE = 'Active';
export const RECIPIENT_STATUS__DECEASED = 'Deceased';
export const RECIPIENT_STATUS__EARLY_DISCHARGED = 'Early Discharged';
export const RECIPIENT_STATUS__END_OF_PROJECT = 'End of Project';

export const RECIPIENT_CONSENT__NOT_REQUIRED = 'Not Required';
export const RECIPIENT_CONSENT__PENDING = 'Pending';
export const RECIPIENT_CONSENT__OBTAINED = 'Obtained';

export const RECIPIENT_STATUSES = [
  RECIPIENT_STATUS__INITIAL,
  RECIPIENT_STATUS__REJECTED,
  RECIPIENT_STATUS__ACTIVE,
  RECIPIENT_STATUS__DECEASED,
  RECIPIENT_STATUS__EARLY_DISCHARGED,
  RECIPIENT_STATUS__END_OF_PROJECT,
];

export const RECIPIENT_STATUS_OPTIONS = [
  {
    value: RECIPIENT_STATUS__REJECTED,
    label: 'Rejected',
  },
  {
    value: RECIPIENT_STATUS__ACTIVE,
    label: 'Active',
  },
  {
    value: RECIPIENT_STATUS__DECEASED,
    label: 'Deceased',
  },
  {
    value: RECIPIENT_STATUS__EARLY_DISCHARGED,
    label: 'Early discharge',
  },
  {
    value: RECIPIENT_STATUS__END_OF_PROJECT,
    label: 'End of 3 years (TOC)',
  },
];

// Ethnicity

export const RECIPIENTS_ETHNICITY__OTHER = 'Other';
export const RECIPIENTS_ETHNICITY__OTHER_NZ = 'NZ-Other';
export const RECIPIENTS_ETHNICITY__OTHER_AU = 'AU-Other';
export const RECIPIENTS_ETHNICITY__OTHER_LIST = [
  RECIPIENTS_ETHNICITY__OTHER,
  RECIPIENTS_ETHNICITY__OTHER_NZ,
  RECIPIENTS_ETHNICITY__OTHER_AU,
];

export const RECIPIENT_ETHNICITIES = [
  'New Zealand European',
  'Māori',
  'Samoan',
  'Cook Island Maori',
  'Tongan',
  'Niuean',
  'Chinese',
  'Indian',
  RECIPIENTS_ETHNICITY__OTHER,
];

export const RECIPIENT_ETHNICITY_OPTIONS = RECIPIENT_ETHNICITIES.map(
  ethnicity => ({
    value: ethnicity,
    label: ethnicity,
  }),
);

export const RECIPIENT_ETHNICITIES_QLD = [
  'Australian',
  'Australian Aboriginal and Torres Strait Islander',
  'Chinese',
  'English',
  'Irish',
  'Italian',
  'Scottish',
  'German',
  'Maori',
  'New Zealand',
  RECIPIENTS_ETHNICITY__OTHER,
];

export const RECIPIENT_ETHNICITIES_QLD_OPTIONS = RECIPIENT_ETHNICITIES_QLD.map(
  ethnicity => ({
    value: ethnicity,
    label: ethnicity,
  }),
);

export const RECIPIENT_ETHNICITIES_IHIS = [
  'Chinese',
  'Malay',
  'Indian',
  RECIPIENTS_ETHNICITY__OTHER,
];

export const RECIPIENT_ETHNICITIES_IHIS_OPTIONS = RECIPIENT_ETHNICITIES_IHIS.map(
  ethnicity => ({
    value: ethnicity,
    label: ethnicity,
  }),
);

// Organization specific
export const RECIPIENTS_PRE_C_ETHNICITY = [
  'NZ-New Zealand European',
  'NZ-Māori',
  'NZ-Samoan',
  'NZ-Cook Island Maori',
  'NZ-Tongan',
  'NZ-Niuean',
  'NZ-Chinese',
  'NZ-Indian',
  RECIPIENTS_ETHNICITY__OTHER_NZ,
  'AU-Australian',
  'AU-Australian Aboriginal and Torres Strait Islander',
  'AU-Chinese',
  'AU-English',
  'AU-Irish',
  'AU-Italian',
  'AU-Scottish',
  'AU-German',
  'AU-Maori',
  'AU-New Zealand',
  RECIPIENTS_ETHNICITY__OTHER_AU,
];

export const RECIPIENT_PRE_C_ETHNICITY_OPTIONS = RECIPIENTS_PRE_C_ETHNICITY.map(
  ethnicity => ({
    value: ethnicity,
    label: ethnicity,
  }),
);
