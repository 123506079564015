import PropTypes from 'prop-types';
import React, {
  forwardRef,
} from 'react';
import isEqual from 'lodash/isEqual';
import {
  connect,
} from 'react-redux';
import {
  compose,
} from 'recompose';
import {
  createSelector,
  createStructuredSelector,
  defaultMemoize,
} from 'reselect';
import {
  withContext,
} from './QuestionnaireContext';
import SelectorsHub from './SelectorsHub';
import {
  identity,
  constant,
  property,
  argument,
  higherOrderSelector,
} from '../../utilsClient/selectors';
import getValue from './getValue';
import {
  PropTypesRef,
} from './propTypes';
import isReactNative from './isReactNative';

class Field extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.pending) {
      if (state.value !== props.value) {
        return null;
      }
      return {
        pending: false,
      };
    }
    return {
      value: props.value,
    };
  }

  // TODO: Implement optional debounce, e.g. handle state locally, and sync to store after some delay.
  constructor(props) {
    super(props);
    this.state = {};
    this.handleOnChange = this.handleOnChange.bind(this);

    this.getInput = createStructuredSelector({
      value: argument(0, 'value'), // state.value
      onChange: constant(this.handleOnChange),
    });

    this.getMeta = createStructuredSelector({
      error: createSelector(
        property('context'),
        property('name'),
        property('value'),
        property('validationError'),
        (context, name, value, validationError) => validationError || context.question.getFieldError(name, value),
      ),
      formulaError: createSelector(
        property('result'),
        result => result && result.error,
      ),
      touched: createSelector(
        property('touched'),
        property('validationError'),
        // FIXME: This is a temporary solution, not sure if this logic is alright.
        (touched, validationError) => touched || !!validationError,
      ),
      submitFailed: createSelector(
        property('validationError'),
        validationError => !!validationError,
      ),
    });

    this.debounceTimeout = null;
    this.debounce = (action, delay) => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(action, delay);
    };
  }

  componentWillUnmount() {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
      delete this.debounceTimeout;
    }
  }

  getDefaultChildrenProps(state = this.state, props = this.props) {
    const {
      disabled,
      context,
      contextOptions,
    } = props;
    const childrenProps = {
      meta: this.getMeta(state, props),
      input: this.getInput(state, props),
    };
    // NOTE: Can either be disabled explicitly,
    //       via context property, or it can be
    //       a direct question property.
    if (
      disabled ||
      (contextOptions && contextOptions.disabled) ||
      context.question.shouldDisableInput()
    ) {
      childrenProps.disabled = true;
    }
    if (context.question.getLabel()) {
      childrenProps.label = context.question.getLabel();
    }
    return childrenProps;
  }

  dispatchChangeToStore(name, value) {
    const {
      context,
      dispatch,
      onChange,
    } = this.props;
    dispatch(context.setValue(name, value));
    if (onChange) {
      onChange(name, value, {
        context,
        dispatch,
      });
    }
  }

  handleOnChange(event) {
    const {
      name,
      contextOptions,
    } = this.props;
    const value = getValue(event, isReactNative);
    const delay = contextOptions && contextOptions.debounceEdit;
    if (delay) {
      // Update local state immediately, but dispatch to store with delay.
      // Setting pending flag will prevent updates to state via props.value
      // until props.value is equal to state.value at least once. So one can
      // think about it as component opting-out from controlled mode for
      // a fraction of second.
      this.setState({
        value,
        pending: true,
      });
      this.debounce(() => {
        this.dispatchChangeToStore(name, value);
        this.setState(state => (state.pending ? {
          pending: false,
        } : null));
      }, delay);
    } else {
      this.setState({
        value,
      });
      this.dispatchChangeToStore(name, value);
    }
  }

  render() {
    const {
      name,
      touched,
      children,
      component: Component,
      context,
      dispatch,
      result,
      value,
      validationError,
      contextOptions,
      disabled,
      onChange,
      forwardedRef,
      ...other
    } = this.props;
    if (Component) {
      return (
        <Component
          ref={forwardedRef}
          {...this.getDefaultChildrenProps()}
          {...other}
        />
      );
    }
    if (typeof children === 'function') {
      return children({
        ...this.getDefaultChildrenProps(),
        ...other,
      });
    }
    return null;
  }
}

Field.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  validationError: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.func,
  component: PropTypes.elementType,
  context: PropTypes.instanceOf(SelectorsHub).isRequired,
  dispatch: PropTypes.func.isRequired,
  result: PropTypes.shape({
    value: PropTypes.any,
    error: PropTypes.any,
  }),
  disabled: PropTypes.bool,
  contextOptions: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  forwardedRef: PropTypesRef,
};

Field.defaultProps = {
  name: 'value',
  value: null,
  validationError: null,
  touched: false,
  children: null,
  component: null,
  result: null,
  disabled: false,
  contextOptions: null,
  onChange: null,
  forwardedRef: null,
};

export default compose(
  withContext({
    forwardRef: true,
  }),
  connect(
    () => {
      const selectFormValue = higherOrderSelector(
        property('context'),
        property('name'),
        (context, name) => context.createFormValueSelector(name),
      );
      const selectValidationError = higherOrderSelector(
        property('context'),
        property('name'),
        (context, name) => context.createValidationErrorSelector(name),
      );
      const selectFieldTouched = higherOrderSelector(
        property('context'),
        property('name'),
        (context, name) => context.createTouchedSelector(name),
      );
      const selectQuestionnaire = higherOrderSelector(
        property('context'),
        context => context.select.questionnaire(),
      );
      const selectOriginalQuestion = createSelector(
        property('context.questionId'),
        selectQuestionnaire,
        (questionId, questionnaire) => questionnaire && questionnaire.getQuestionById(questionId),
      );
      const selectFormulaResult = createSelector(
        createSelector(
          (state, props) => props.context.select.evaluationScope(props.context.scopeKey)(state),
          selectOriginalQuestion,
          (evaluationScope, question) => {
            if (
              question &&
              question.isFormula() &&
              question.hasFormulaToEvaluate()
            ) {
              return evaluationScope.lookupAnswer(question.id);
            }
            return null;
          },
        ),
        defaultMemoize(identity, isEqual),
      );
      return createStructuredSelector({
        touched: selectFieldTouched,
        value: createSelector(
          property('name'),
          selectFormValue,
          selectFormulaResult,
          (name, value, result) => {
            if (name !== 'value') {
              return value;
            }
            if (result && !result.error) {
              return result.value;
            }
            return value;
          },
        ),
        validationError: selectValidationError,
        result: selectFormulaResult,
        contextOptions: higherOrderSelector(property('context'), context => context.select.options()),
      });
    },
    null,
    null,
    {
      forwardRef: true,
    },
  ),
  // eslint-disable-next-line react/no-multi-comp
)(forwardRef((props, ref) => (
  <Field
    {...props}
    forwardedRef={ref}
  />
)));
