import {
  PROPERTY_TYPE__DATE,
  PROPERTY_TYPE__USER_ID,
} from './BaseModel';

export const ANSWERS_SHEET_PROPERTY__STARTED_AT = 'startedAt';
export const ANSWERS_SHEET_PROPERTY__STARTED_BY = 'startedBy';

export const ANSWERS_SHEET_PROPERTY__RECRUITED_AT = 'recruitedAt';
export const ANSWERS_SHEET_PROPERTY__RECRUITED_BY = 'recruitedBy';

export const ANSWERS_SHEET_PROPERTY__COMPLETED_AT = 'completedAt';
export const ANSWERS_SHEET_PROPERTY__COMPLETED_BY = 'completedBy';

export const ANSWERS_SHEET_PROPERTY__DISCHARGED_AT = 'dischargedAt';
export const ANSWERS_SHEET_PROPERTY__DISCHARGED_BY = 'dischargedBy';

export const ANSWERS_SHEET_PROPERTIES = [
  ANSWERS_SHEET_PROPERTY__STARTED_AT,
  ANSWERS_SHEET_PROPERTY__STARTED_BY,
  ANSWERS_SHEET_PROPERTY__RECRUITED_AT,
  ANSWERS_SHEET_PROPERTY__RECRUITED_BY,
  ANSWERS_SHEET_PROPERTY__COMPLETED_AT,
  ANSWERS_SHEET_PROPERTY__COMPLETED_BY,
];

export const ANSWERS_SHEET_PROPERTY_OPTIONS = [
  {
    value: ANSWERS_SHEET_PROPERTY__STARTED_AT,
    label: 'Started at',
  },
  {
    value: ANSWERS_SHEET_PROPERTY__STARTED_BY,
    label: 'Started by',
  },
  {
    value: ANSWERS_SHEET_PROPERTY__RECRUITED_AT,
    label: 'Recruited at',
  },
  {
    value: ANSWERS_SHEET_PROPERTY__RECRUITED_BY,
    label: 'Recruited by',
  },
  {
    value: ANSWERS_SHEET_PROPERTY__COMPLETED_AT,
    label: 'Completed at',
  },
  {
    value: ANSWERS_SHEET_PROPERTY__COMPLETED_BY,
    label: 'Completed by',
  },
  {
    value: ANSWERS_SHEET_PROPERTY__DISCHARGED_AT,
    label: 'Discharged at',
  },
  {
    value: ANSWERS_SHEET_PROPERTY__DISCHARGED_BY,
    label: 'Discharged by',
  },
];

export const ANSWERS_SHEET_PROPERTY_DEFINITIONS = {
  [ANSWERS_SHEET_PROPERTY__STARTED_AT]: {
    key: 'startedAt',
    type: PROPERTY_TYPE__DATE,
  },
  [ANSWERS_SHEET_PROPERTY__STARTED_BY]: {
    key: 'startedBy',
    type: PROPERTY_TYPE__USER_ID,
  },
  [ANSWERS_SHEET_PROPERTY__RECRUITED_AT]: {
    key: 'recruitedAt',
    type: PROPERTY_TYPE__DATE,
  },
  [ANSWERS_SHEET_PROPERTY__RECRUITED_BY]: {
    key: 'recruitedBy',
    type: PROPERTY_TYPE__USER_ID,
  },
  [ANSWERS_SHEET_PROPERTY__COMPLETED_AT]: {
    key: 'completedAt',
    type: PROPERTY_TYPE__DATE,
  },
  [ANSWERS_SHEET_PROPERTY__COMPLETED_BY]: {
    key: 'completedBy',
    type: PROPERTY_TYPE__USER_ID,
  },
};

export const ANSWERS_SHEET_USER_FOREIGN_KEYS = [
  'assigneeId',
  'createdBy',
  'updatedBy',
  'suspendedBy',
  'canceledBy',
  'completedBy',
  'amendedBy',
  'recruitedBy',
  'startedBy',
  'pausedBy',
  'dischargedBy',
];

export const ANSWERS_SHEET_SESSION_KEEP_ALIVE_SECONDS = 15;

export const SIGNED_NOTE_TYPE__SAVED = 'Saved';
export const SIGNED_NOTE_TYPE__AMENDED = 'Amended';
export const SIGNED_NOTE_TYPE__REASSIGNED = 'Reassigned';

export const SIGNED_NOTE_TYPES = [
  SIGNED_NOTE_TYPE__SAVED,
  SIGNED_NOTE_TYPE__AMENDED,
  SIGNED_NOTE_TYPE__REASSIGNED,
];

export const ANSWERS_SHEET_STATE_TRANSITION_HOOK__CHANGE_STATE = 'Change State';

export const ANSWERS_SHEET_STATE_TRANSITION_HOOK_TYPES = [
  ANSWERS_SHEET_STATE_TRANSITION_HOOK__CHANGE_STATE,
];

export const ANSWERS_SHEET_TYPE__RESPONSE = 'Response';
export const ANSWERS_SHEET_TYPE__CONSENT = 'Consent';
export const ANSWERS_SHEET_TYPE__APPLICATION = 'Application';

export const ANSWERS_SHEET_TYPES = [
  ANSWERS_SHEET_TYPE__RESPONSE,
  ANSWERS_SHEET_TYPE__CONSENT,
  ANSWERS_SHEET_TYPE__APPLICATION,
];

export const ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR = 'Cursor'; // the default one
export const ANSWERS_SHEET_NAVIGATION_TYPE__CAT = 'CAT';

export const ANSWERS_SHEET_NAVIGATION_TYPES = [
  ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR,
  ANSWERS_SHEET_NAVIGATION_TYPE__CAT,
];

export const ANSWERS_SHEET_NAVIGATION_TYPE_OPTIONS = [
  {
    value: ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR,
    label: 'Cursor',
  },
  {
    value: ANSWERS_SHEET_NAVIGATION_TYPE__CAT,
    label: 'CAT',
  },
];
