import styled, {
  createGlobalStyle,
} from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import ModalANTD from 'antd/es/modal';
import 'antd/es/modal/style/css';
import {
  useTranslation,
} from 'react-i18next';
import Cluster from '../primitives/Cluster';
import Button from '../Button';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const StyledModal = styled(ModalANTD)`
  /* TODO: Remove after removing antd */
  && {
    .ant-modal-footer button + button {
      margin-bottom: 6px;
      margin-left: 6px;
    }

    .ant-modal-header,
    .ant-modal-footer {
      padding: ${theme('space.3')};
      border: 0;
    }

    /* Color Theme */

    .ant-modal-content {
      background-color: ${theme('color.surface')};
    }

    .ant-modal-body {
      background-color: ${theme('color.background')};
      color: ${theme('color.onBackground')};
    }

    .ant-modal-header {
      background-color: transparent;
    }

    .ant-modal-title,
    .ant-modal-close-x {
      color: ${theme('color.onSurface')};
    }

    .ant-modal-close-x {
      line-height: 70px;
      height: 70px;
      width: 70px;
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  ${props => props.direction === 'rtl' &&
    `
    .ant-modal {
      text-align: initial !important;
    }

    .ant-modal-close {
      right: initial !important;
      left: 0 !important;
    }
  `}
`;

const Modal = (props) => {
  const {
    t,
    i18n,
  } = useTranslation('components');

  const {
    footer,
    okText,
    okType,
    cancelText,
    onCancel,
    onOk,
    isOkDisabled,
    confirmLoading,
  } = props;

  const direction = i18n.dir(i18n.language);

  return (
    <>
      <GlobalStyle direction={direction} />
      <StyledModal
        {...props}
        footer={(
          <Cluster
            space={2}
            justify="flex-end"
            overflow="initial"
          >
            {footer || (
            <>
              <Button
                data-testid="button-cancel"
                onClick={onCancel}
              >
                {cancelText || t('Modal.cancel')}
              </Button>
              <Button
                data-testid="button-ok"
                type={okType}
                onClick={onOk}
                disabled={isOkDisabled}
                loading={confirmLoading}
              >
                {okText || t('Modal.ok')}
              </Button>
            </>
            )}
          </Cluster>
)}
      />
    </>
  );
};

Modal.propTypes = {
  footer: PropTypes.node,
  okText: PropTypes.string,
  okType: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  isOkDisabled: PropTypes.bool,
  confirmLoading: PropTypes.bool,
};

Modal.defaultProps = {
  footer: null,
  okType: 'primary',
  okText: '',
  cancelText: '',
  onCancel: () => {},
  onOk: () => {},
  isOkDisabled: false,
  confirmLoading: false,
};

export default Modal;
