import sampleSize from 'lodash/sampleSize';
import {
  ANSWER_VALUE_MAX_LENGTH,
  QUESTION_TYPE__SELECT_MANY,
} from '../../../constants';
import Question from '../Question';

export default class QuestionSelectMany extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SELECT_MANY;
  }

  getRawKeys() {
    const rawKeys = [
      'value',
    ];
    if (this.useOther()) {
      rawKeys.push('other');
    }
    if (this.useText1()) {
      rawKeys.push('text1');
    }
    if (this.useText2()) {
      rawKeys.push('text2');
    }
    return rawKeys;
  }

  getValueError(value, valueType) {
    const error = super.getValueError(value, valueType);
    if (error) {
      return error;
    }
    if (value) {
      for (let i = 0; i < value.length; i += 1) {
        if (value[i].length > ANSWER_VALUE_MAX_LENGTH) {
          return `Value too long, expected ${ANSWER_VALUE_MAX_LENGTH} got ${value.length}`;
        }
      }
    }
    return null;
  }

  getRandomAnswer() {
    const nChoices = this.choices ? this.choices.length : 0;
    const choices =
      this.isOptional() && Math.random() < 0.5
        ? []
        : sampleSize(this.choices, 1 + Math.floor(nChoices * Math.random()));
    return {
      value: choices.map(({
        value,
      }) => value),
    };
  }
}

QuestionSelectMany.expectedValueType = {
  type: 'array',
  items: {
    type: 'string',
  },
};

QuestionSelectMany.editorSettings = {
  limits: false,
  choices: true,
  snippets: false,
  additional: true,
};

Question.types[QUESTION_TYPE__SELECT_MANY] = QuestionSelectMany;
