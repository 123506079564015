import {
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__FREE_TEXT,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__IFRAME,
  ANSWER_VALUE_MAX_LENGTH,
} from '../../../constants';
import Question from '../Question';

export default class QuestionText extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SHORT_TEXT;
  }

  getRawKeys() {
    const rawKeys = [
      'value',
    ];
    if (this.useOther()) {
      rawKeys.push('other');
    }
    if (this.useText1()) {
      rawKeys.push('text1');
    }
    if (this.useText2()) {
      rawKeys.push('text2');
    }
    return rawKeys;
  }

  getValueError(value, valueType) {
    const error = super.getValueError(value, valueType);
    if (error) {
      return error;
    }
    if (value && value.length > ANSWER_VALUE_MAX_LENGTH) {
      return `Value too long, expected ${ANSWER_VALUE_MAX_LENGTH} got ${value.length}`;
    }
    return null;
  }
}

QuestionText.expectedValueType = {
  type: 'string',
};

QuestionText.editorSettings = {
  limits: false,
  choices: false,
  snippets: true,
  additional: true,
};

Question.types[QUESTION_TYPE__SHORT_TEXT] = QuestionText;
Question.types[QUESTION_TYPE__FREE_TEXT] = QuestionText;
Question.types[QUESTION_TYPE__DATE] = QuestionText;
Question.types[QUESTION_TYPE__IFRAME] = QuestionText;
