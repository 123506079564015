import styled, {
  createGlobalStyle,
} from 'styled-components/macro';
import React from 'react';
import Zedoc from '../common/components/Zedoc';
import Loading from '../common/components/Loading';
import {
  theme,
} from '../common/utilsClient/cssHelpers';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${theme('color.background')} !important;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: ${theme('space.3')}; */
  padding: 1.5rem;
`;

const AppSkeleton = () => {
  return (
    <>
      <GlobalStyle />
      <StyledWrapper>
        <Zedoc />
        <Loading />
      </StyledWrapper>
    </>
  );
};

export default AppSkeleton;
