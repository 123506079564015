import styled from 'styled-components/macro';
import theming from 'styled-theming';
import React from 'react';
import {
  motion,
} from 'framer-motion';
// import usePrefersReducedMotion from '../utils/usePrefersReducedMotion';

const mainFill = theming('mode', {
  light: 'rgb(51, 64, 83, 1)',
  dark: 'rgba(255, 255, 255, 1)',
});

const StyledMotion = styled(motion.svg)`
  max-height: 3rem;
  fill: ${mainFill};

  @media (max-width: 500px) {
    max-height: 2.25rem;
  }
`;

const ellipseFill = theming('mode', {
  light: '#f2f2f3',
  dark: '#333335',
});

const StyledEllipse = styled.ellipse`
  fill: #f2f2f3;
  fill: ${ellipseFill};
`;

const Zedoc = () => {
  const prefersReducedMotion = true;

  const variants = prefersReducedMotion
    ? {}
    : {
      initial: {
        opacity: 0,
        pathLength: 0,
        stroke: '#334053',
        strokeWidth: 5,
        fill: 'rgb(255, 255, 255, 0)',
      },
      animate: {
        opacity: 1,
        pathLength: 1,
        fill: [
          'rgb(255, 255, 255, 0)',
          'rgb(255, 255, 255, 0)',
          'rgb(51, 64, 83, 1)',
        ],
        transition: {
          duration: 3,
        },
      },
    };

  return (
    <StyledMotion
      width="100%"
      height="100%"
      viewBox="0 0 1024 326"
      xmlns="http://www.w3.org/2000/svg"
      initial="initial"
      animate="animate"
      fill="rgb(51, 64, 83, 1)"
    >
      <motion.path
        d="M 108.7 225.3 L 198.2 225.3 C 206.29999999999998 225.3 211.7 229.70000000000002 213 237.4 C 214.1 243.70000000000002 209.3 250.8 203 252.1 C 201.2 252.5 199.2 252.7 197.4 252.7 L 86.3 252.7 C 76.2 252.7 69.6 247.6 67.69999999999999 238.2 C 66.49999999999999 232.5 68.99999999999999 227.79999999999998 72.49999999999999 223.5 L 117.79999999999998 168 C 134.39999999999998 147.7 151 127.5 167.59999999999997 107.3 C 168.59999999999997 106 169.59999999999997 104.7 171.09999999999997 102.6 L 166.79999999999995 102.6 C 139.59999999999997 102.6 112.29999999999995 102.5 85.09999999999995 102.69999999999999 C 77.99999999999996 102.69999999999999 70.89999999999995 97.89999999999999 70.59999999999995 89.19999999999999 C 70.29999999999995 81.69999999999999 76.79999999999995 75.69999999999999 84.99999999999996 75.69999999999999 L 193.89999999999998 75.69999999999999 C 201.2 75.69999999999999 206.79999999999998 78.79999999999998 209.89999999999998 85.39999999999999 C 213.09999999999997 92.19999999999999 211.7 98.6 206.89999999999998 104.39999999999999 C 194.79999999999998 119.1 182.79999999999998 133.89999999999998 170.7 148.6 C 158.79999999999998 163.2 146.79999999999998 177.7 134.79999999999998 192.2 C 126.29999999999998 202.6 117.79999999999998 213.1 109.29999999999998 223.5 C 109.19999999999999 224 109.19999999999999 224.4 108.69999999999999 225.3 Z"
        variants={variants}
      />
      <motion.path
        d="M 280.8 103.5 L 280.8 150.9 C 282.2 151 283.6 151.1 284.90000000000003 151.1 L 352 151.1 C 359.8 151.1 365.3 156.29999999999998 365.5 163.6 C 365.7 171.2 360 177 352.3 177 L 280.9 177 L 280.9 224.9 C 282.29999999999995 225 283.59999999999997 225.1 285 225.1 L 363.2 225.1 C 371.2 225.1 375.9 228.7 377.9 235.79999999999998 C 379.79999999999995 242.6 374.7 250.89999999999998 367.7 252.2 C 365.5 252.6 363.3 252.79999999999998 361.09999999999997 252.79999999999998 L 267.09999999999997 252.79999999999998 C 262.59999999999997 252.79999999999998 258.4 251.89999999999998 254.99999999999997 248.39999999999998 C 252.49999999999997 245.7 251.19999999999996 242.59999999999997 251.19999999999996 238.99999999999997 C 251.19999999999996 189.09999999999997 251.09999999999997 139.2 251.19999999999996 89.19999999999996 C 251.19999999999996 81.69999999999996 257.09999999999997 75.79999999999995 264.69999999999993 75.69999999999996 C 296.8999999999999 75.59999999999997 329.0999999999999 75.59999999999997 361.29999999999995 75.69999999999996 C 369.4 75.69999999999996 375.29999999999995 81.89999999999996 375.19999999999993 89.79999999999995 C 375.0999999999999 97.49999999999996 369.19999999999993 103.39999999999995 361.19999999999993 103.39999999999995 L 284.8999999999999 103.39999999999995 C 283.49999999999994 103.49999999999994 282.2999999999999 103.49999999999994 280.7999999999999 103.49999999999994 Z"
        variants={variants}
      />
      <motion.path
        d="M 446.5 224.9 C 447.9 225 448.7 225.1 449.5 225.1 C 462.7 225 475.9 225.3 489.1 224.5 C 505.1 223.6 519.9 219.1 531.6 207.3 C 539.7 199 543.8 188.8 545.4 177.5 C 547.1 165.4 546.8 153.4 543.4 141.5 C 537.9 122.6 524.7 111.7 506.2 106.5 C 496.4 103.7 486.4 102.9 476.3 102.8 C 467.5 102.7 458.8 102.8 450 102.8 C 449 102.8 447.9 102.9 446.5 103 L 446.5 107.8 C 446.4 117.6 446.6 127.4 445.9 137.2 C 445.3 144.9 438 150.2 430.3 149.6 C 423 149.1 417.1 142.8 417.1 135.2 C 417 119.8 417 104.4 417.1 89 C 417.1 81.6 422.8 75.9 430.4 75.8 C 448.9 75.7 467.4 75.3 485.8 75.9 C 504.2 76.5 522.1 80.1 538.2 89.8 C 557.7 101.5 569.3 118.8 574 140.9 C 574.5 143.1 575.2 145.2 575.1 147.4 C 575 150.7 576.6 151.1 579.4 151.1 C 589.8 151 600.3 151 610.8 151.1 C 613 151.1 613.9 150.4 614.3 148.2 C 620.2 119 644.6 96.3 674.1 92.2 C 712.2 86.8 747.4 112.2 754.4 149.7 C 758.5 171.8 753.2 191.8 738.7 209.2 C 727.3 222.8 712.6 231.1 695.2 233.7 C 673.7 237 654.4 231.4 637.7 217.2 C 625.3 206.6 617.6 193.1 614 177.1 L 576 177.1 C 574.4 183.8 573.2 190.5 571.1 196.8 C 561.7 224.8 541.9 241.4 513.6 248.5 C 501.1 251.7 488.3 252.6 475.4 252.8 C 461 253.1 446.7 252.9 432.3 252.9 C 424.4 252.9 419.3 249.4 417.4 242.7 C 417.1 241.7 417 240.6 417 239.6 C 417 224.2 416.9 208.8 417 193.4 C 417 186.5 422.2 180.3 428.6 179.2 C 436.1 177.9 443.1 181.8 445.4 188.8 C 446.1 191 446.4 193.3 446.4 195.6 C 446.5 204.1 446.4 212.7 446.4 221.3 C 446.5 222.3 446.5 223.5 446.5 224.9 Z"
        variants={variants}
      />
      <StyledEllipse
        transform="matrix(-0.707104, 0.707109, -0.707108, -0.707106, 1329.748454, -112.916309)"
        cx="651.858"
        cy="262.322"
        rx="43.223"
        ry="43.223"
      />
      <motion.path
        d="M 784.6 163.5 C 784.9 143.5 790 125.2 802 109.1 C 816.6 89.5 835.9 77.39999999999999 860 73.19999999999999 C 879.5 69.79999999999998 898.7 70.69999999999999 916.8 78.99999999999999 C 924.3 82.39999999999999 931.1999999999999 87.09999999999998 938 91.89999999999999 C 943.6 95.8 944.1 105.1 939.8 111 C 935.6999999999999 116.5 927.4 118.3 921.4 114.7 C 919 113.2 916.6999999999999 111.5 914.3 110 C 901.9 101.8 888.0999999999999 99.8 873.5999999999999 100.8 C 860.5999999999999 101.7 848.8999999999999 105.7 838.8 113.8 C 824.8 124.89999999999999 817 139.3 815.9 157.3 C 815 171.8 817.4 185.5 825.5 197.60000000000002 C 839.3 218.00000000000003 858.6 227.70000000000002 883.4 225.90000000000003 C 895.6 225.00000000000003 906.4 220.80000000000004 916.1 213.30000000000004 C 924.7 206.70000000000005 934.9 209.10000000000005 938.9 218.40000000000003 C 941.1 223.60000000000002 939.5 230.70000000000005 935.1 234.20000000000005 C 920.5 246.10000000000005 904.1 253.20000000000005 885.2 254.50000000000006 C 861 256.20000000000005 838.8000000000001 251.00000000000006 819.4000000000001 236.00000000000006 C 801.5000000000001 222.10000000000005 790.1000000000001 204.10000000000005 786.5000000000001 181.50000000000006 C 785.5000000000001 175.50000000000006 785.2000000000002 169.50000000000006 784.6000000000001 163.50000000000006 Z"
        variants={variants}
      />
    </StyledMotion>
  );
};

export default Zedoc;
