import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import Formula from '../Formula';

/**
 * Evaluates the formula assigned to this question.
 * @param {EvaluationScope} formulaScope - an object created with EvaluationScope.create() method
 */
export function evaluateFormula(question, formulaScope) {
  if (question.hasFormulaToEvaluate()) {
    const formula = Formula.create(question.settings.formula);
    const {
      value,
      error,
    } = formula.evaluate(formulaScope);
    if (error) {
      return {
        error,
      };
    }
    return {
      value,
    };
  }
  return {
    error: {
      message: 'No formula to evaluate',
    },
  };
}

/**
 * Return a form values object containing all evaluated formulas.
 * @param {EvaluationScope} evaluationScope
 * @returns {Object}
 */
export function evaluateFormulas(evaluationScope) {
  const questionnaire = evaluationScope.getQuestionnaire();
  if (!questionnaire) {
    throw new Error('evaluateFormulas requires scope bound to a questionnaire');
  }
  const formulas = {};
  questionnaire.forEachQuestion(
    (question) => {
      const answer = evaluationScope.getAnswer(question.id);
      const newAnswer = {};
      if (question.isFormula()) {
        Object.assign(newAnswer, evaluateFormula(question, evaluationScope));
      } else if (question.isCollection()) {
        const newElements = {};
        forEach(answer && answer._elementsOrder, (elementId) => {
          newElements[elementId] = {
            _elements: evaluateFormulas(
              evaluationScope
                .getOrCreateSubScope(question.id)
                .getOrCreateSubScope(elementId),
            ),
          };
          if (!newElements[elementId]._elements) {
            delete newElements[elementId];
          }
        });
        if (!isEmpty(newElements)) {
          newAnswer._elementsOrder = answer._elementsOrder;
          newAnswer._elements = newElements;
        }
      }
      if (!isEmpty(newAnswer)) {
        formulas[question.id] = newAnswer;
      }
    },
    {
      sectionId: evaluationScope.getCollectionQuestionId(),
      stopRecursion: q => q.isCollection(),
    },
  );
  if (isEmpty(formulas)) {
    return null;
  }
  return formulas;
}

export function getAtPath(variable, path) {
  let currentValue = variable;
  for (let i = 0; currentValue && i < path.length; i += 1) {
    currentValue =
      currentValue && currentValue._elements && currentValue._elements[path[i]];
  }
  return currentValue;
}
