import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import Question from '../Question';
import getScopeKey from './getScopeKey';

export const CURSOR_TYPE__ROOT = 'root';
export const CURSOR_TYPE__END = 'end';
export const CURSOR_TYPE__QUESTION = 'question';
export const CURSOR_TYPE__ELEMENT = 'element';
export const CURSOR_TYPE__PROLOGUE = 'prologue';
export const CURSOR_TYPE__EPILOGUE = 'epilogue';

const defaultStepFilter = cursor => cursor.isVisible();
const identity = x => x;

class QuestionCursorBase {
  /**
   * Creates an instance of a cursor that is capable of traversing questionnaire
   * in both directions in a context of current formValues, which can affect elements of collections,
   * and current (dynamic) properties, which can affect individual questions visibility.
   * @param {Object} options
   * @param {String} options.type
   * @param {Questionnaire} options.questionnaire
   * @param {String} options.questionId
   * @param {Object} options.formValues
   * @param {Object} options.properties
   * @param {QuestionCursorBase} options.parentCursor
   * @param {String[]} options.hierarchy - a list of all elements in the current question hierarchy
   * @param {String} [options.elementId] - only relevant for CURSOR_TYPE__ELEMENT
   * @param {Function} [options.stepFilter] - by default it skips all hidden steps (corresponding to hidden questions)
   * @param {Function|String} [options.sortedBy] - use custom sorting method
   * @param {Boolean} [options.flatSections] - flatten all sections
   */
  constructor({
    type,
    questionnaire,
    questionId,
    formValues,
    properties,
    parentCursor,
    hierarchy,
    elementId,
    stepFilter = defaultStepFilter,
    sortedBy,
    flatSections,
  }) {
    Object.assign(this, {
      type,
      questionnaire,
      questionId,
      formValues,
      properties,
      parentCursor,
      hierarchy,
      elementId,
      stepFilter,
      sortedBy,
      flatSections,
    });
    if (!parentCursor && type !== CURSOR_TYPE__ROOT) {
      this.parentCursor = new this.constructor({
        type: CURSOR_TYPE__ROOT,
        questionnaire,
        formValues,
        properties,
        stepFilter,
        sortedBy,
        flatSections,
      });
    }
  }

  get getSortingProperty() {
    const {
      sortedBy,
    } = this;
    let getSortingProperty;
    if (typeof sortedBy === 'function') {
      getSortingProperty = sortedBy;
    } else if (typeof sortedBy === 'string') {
      getSortingProperty = question => question[sortedBy];
    }
    Object.defineProperty(this, 'getSortingProperty', {
      value: getSortingProperty,
    });
    return getSortingProperty;
  }

  get localProperties() {
    const scopeKey = this.getScopeKey();
    const localProperties =
      (scopeKey ? get(this.properties, scopeKey) : this.properties) || {};

    Object.defineProperty(this, 'localProperties', {
      value: localProperties,
    });
    return localProperties;
  }

  get question() {
    let question =
      this.questionnaire && this.questionnaire.getQuestionById(this.questionId);
    if (question && this.localProperties) {
      const questionNewProperties = this.localProperties[this.questionId];
      question = Question.create({
        ...question,
        ...questionNewProperties,
      });
    }
    Object.defineProperty(this, 'question', {
      value: question,
    });
    return question;
  }

  get children() {
    const {
      questionnaire,
    } = this;
    let children = this.isFlat()
      ? questionnaire.mapQuestions(identity, {
        sectionId: this.questionId,
        stopRecursion: q => q.isCollection(),
      })
      : questionnaire.getChildQuestions(this.questionId);
    if (this.getSortingProperty) {
      children = sortBy(children, (question) => {
        const properties = this.localProperties[question.id];
        if (properties) {
          return this.getSortingProperty(
            Object.assign(Object.create(question), properties),
          );
        }
        return this.getSortingProperty(question);
      });
    }
    Object.defineProperty(this, 'children', {
      value: children,
    });
    return children;
  }

  get indexByQuestionId() {
    const indexByQuestionId = {};
    forEach(this.children, (question, idx) => {
      indexByQuestionId[question.id] = idx;
    });
    Object.defineProperty(this, 'indexByQuestionId', {
      value: indexByQuestionId,
    });
    return indexByQuestionId;
  }

  getQuestionIndex(questionId) {
    const {
      sortedBy,
      questionnaire,
    } = this;
    if (!sortedBy && !this.isFlat()) {
      return questionnaire && questionnaire.getIndexInSection(questionId);
    }
    return this.indexByQuestionId[questionId];
  }

  getScopeKey() {
    if (!this.questionnaire) {
      return undefined;
    }
    let hierarchy = this.hierarchy || [];
    if (this.isElement()) {
      hierarchy = [
        ...hierarchy,
        this.elementId,
      ];
    }
    return getScopeKey(this.questionnaire, this.questionId, hierarchy);
  }

  getAnswer() {
    const scopeKey = this.getScopeKey();
    const formValues = scopeKey
      ? get(this.formValues, scopeKey)
      : this.formValues;
    return formValues && formValues[this.questionId];
  }

  hasAnswer() {
    return !!this.getAnswer();
  }

  getValue() {
    const answer = this.getAnswer();
    return answer && answer.value;
  }

  getElementsOrder() {
    const answer = this.getAnswer();
    return answer && answer._elementsOrder;
  }

  hasErrors() {
    if (this.question) {
      const answer = this.getAnswer();
      return !!this.question.getErrors(answer);
    }
    return false;
  }

  isNilValue() {
    return Question.isNilValue(this.getValue());
  }

  isValid() {
    switch (this.type) {
      case CURSOR_TYPE__END:
        return false;
      case CURSOR_TYPE__ROOT:
        return true;
      case CURSOR_TYPE__PROLOGUE:
      case CURSOR_TYPE__EPILOGUE:
      case CURSOR_TYPE__QUESTION:
        return !!this.questionId;
      case CURSOR_TYPE__ELEMENT:
        return !!this.elementId;
      default:
        return false;
    }
  }

  isReal() {
    return !this.isVirtual();
  }

  isVirtual() {
    if (this.isRoot()) {
      return true;
    }
    if (this.isElement()) {
      return true;
    }
    if (this.isQuestion()) {
      return (this.isSection() && !this.isFlat()) || this.isCollection();
    }
    return false;
  }

  isAdmissible() {
    if (!this.isValid()) {
      return false;
    }
    return !this.stepFilter || !!this.stepFilter(this);
  }

  setFilters({
    stepFilter,
  }) {
    return this.overwrite({
      stepFilter,
      parentCursor:
        this.parentCursor &&
        this.parentCursor.setFilters({
          stepFilter,
        }),
    });
  }

  overwrite(fields) {
    const {
      type,
      formValues,
      properties,
      questionId,
      questionnaire,
      parentCursor,
      hierarchy,
      elementId,
      stepFilter,
      sortedBy,
      flatSections,
    } = this;
    return new this.constructor({
      type,
      formValues,
      properties,
      questionId,
      questionnaire,
      parentCursor,
      hierarchy,
      elementId,
      stepFilter,
      sortedBy,
      flatSections,
      ...fields,
    });
  }

  isEnd() {
    return this.type === CURSOR_TYPE__END;
  }

  isFlat() {
    return !!this.flatSections;
  }

  isQuestion() {
    return this.type === CURSOR_TYPE__QUESTION;
  }

  isElement() {
    return this.type === CURSOR_TYPE__ELEMENT;
  }

  isRoot() {
    return this.type === CURSOR_TYPE__ROOT;
  }

  isEpilogue() {
    return this.type === CURSOR_TYPE__EPILOGUE;
  }

  isPrologue() {
    return this.type === CURSOR_TYPE__PROLOGUE;
  }

  isSection() {
    return this.question && this.question.isSection();
  }

  isCollection() {
    return this.question && this.question.isCollection();
  }

  isVisible() {
    // NOTE: It's not equivalent to this.question.isVisible(), because
    //       if there's no question the cursor is also considered visible.
    return !this.isHidden();
  }

  isHidden() {
    return this.question && this.question.isHidden();
  }

  // eslint-disable-next-line class-methods-use-this
  firstChildQuestionId() {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  firstChildElementId() {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  nextChildElementId() {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  nextChildQuestionId() {
    throw new Error('Not implemented');
  }

  // eslint-disable-next-line class-methods-use-this
  reverse() {
    throw new Error('Not implemented');
  }

  child(type, properties) {
    const newProperties = {
      ...properties,
      type,
      parentCursor: this,
    };
    if (this.elementId) {
      newProperties.hierarchy = this.hierarchy
        ? [
          ...this.hierarchy,
          this.elementId,
        ]
        : [
          this.elementId,
        ];
      newProperties.elementId = null;
    }
    return this.overwrite(newProperties);
  }

  prev(n) {
    return this.reverse()
      .next(n)
      .reverse();
  }

  next(n = 1) {
    if (n < 0) {
      return this.prev(n);
    }
    if (n === 0 && this.isAdmissible() && this.isReal()) {
      return this;
    }
    let stepsLeft = Math.max(1, n);
    let cursor = this;
    do {
      const isReal = cursor.isReal();
      while (!cursor.isReal() && cursor.isAdmissible()) {
        cursor = cursor.firstChild();
      }
      if (isReal || (cursor.isValid() && !cursor.isAdmissible())) {
        let c = cursor;
        while (c.parentCursor) {
          cursor = c.parentCursor.nextChild(c);
          if (cursor.isValid()) {
            break;
          }
          c = c.parentCursor;
        }
      }
      if (cursor.isAdmissible() && cursor.isReal()) {
        stepsLeft -= 1;
        if (stepsLeft === 0) {
          return cursor;
        }
      }
    } while (cursor.isValid());
    return cursor;
  }

  /**
   * Move cursor to the closest valid position that fulfills the condition counting backwards.
   * Returns self if predicate returns true for the current position.
   * @param {Function} predicate
   */
  lastWhere(predicate) {
    return this.reverse()
      .firstWhere(predicate)
      .reverse();
  }

  /**
   * Move cursor to the closest valid position that fulfills the condition.
   * Returns self if predicate returns true for the current position.
   * @param {Function} predicate
   */
  firstWhere(predicate) {
    let cursor = this;
    while (cursor.isValid()) {
      const {
        question,
      } = cursor;
      if (predicate(question, cursor)) {
        return cursor;
      }
      cursor = cursor.next();
    }
    return cursor;
  }

  /**
   * Move cursor to the previous valid position that fulfills the condition counting backwards.
   * @param {Function} predicate
   */
  prevWhere(predicate) {
    return this.reverse()
      .nextWhere(predicate)
      .reverse();
  }

  /**
   * Move cursor to the next valid position that fulfills the condition.
   * @param {Function} predicate
   */
  nextWhere(predicate) {
    return this.next().firstWhere(predicate);
  }

  /**
   * Returns questionId of the closest valid position (counting backwards) that fulfills the condition.
   * Returns questionId of the current position if condition is already fulfilled.
   * @param {Function} predicate
   */
  lastQuestionIdWhere(predicate) {
    return this.reverse().firstQuestionIdWhere(predicate);
  }

  /**
   * Returns questionId of the closest valid position that fulfills the condition.
   * Returns questionId of the current position if condition is already fulfilled.
   * @param {Function} predicate
   */
  firstQuestionIdWhere(predicate) {
    const cursor = this.firstWhere(predicate);
    return cursor.isValid() ? cursor.questionId : undefined;
  }

  /**
   * Returns questionId of the closest valid position (counting backwards), that corresponds
   * to a position accepted by this.stepFilter.
   */
  lastQuestionId() {
    return this.reverse().firstQuestionId();
  }

  /**
   * Returns questionId of the closest valid position, that corresponds
   * to a position accepted by this.stepFilter.
   */
  firstQuestionId() {
    const cursor = this.next(0);
    return cursor.isValid() ? cursor.questionId : undefined;
  }

  /**
   * Returns questionId of the previous valid position that fulfills the condition.
   * @param {Function} predicate
   */
  prevQuestionIdWhere(predicate) {
    return this.reverse().nextQuestionIdWhere(predicate);
  }

  /**
   * Returns questionId of the next valid position that fulfills the condition.
   * @param {Function} predicate
   */
  nextQuestionIdWhere(predicate) {
    return this.next().firstQuestionIdWhere(predicate);
  }

  /**
   * Returns questionId of the previous valid position, i.e. accepted by this.stepFilter.
   * @param {Function} predicate
   */
  prevQuestionId() {
    return this.reverse().nextQuestionId();
  }

  /**
   * Returns questionId of the next valid position, i.e. accepted by this.stepFilter.
   * @param {Function} predicate
   */
  nextQuestionId() {
    return this.next().firstQuestionId();
  }

  end() {
    return this.overwrite({
      type: CURSOR_TYPE__END,
    });
  }

  describe() {
    const {
      type,
      questionId,
      elementId,
    } = this;
    return omitBy(
      {
        type,
        questionId,
        elementId,
        scopeKey: this.getScopeKey(),
        parentCursor: this.parentCursor && this.parentCursor.describe(),
      },
      isNil,
    );
  }

  firstChild() {
    switch (this.type) {
      case CURSOR_TYPE__ELEMENT:
      case CURSOR_TYPE__ROOT: {
        return this.child(CURSOR_TYPE__QUESTION, {
          questionId: this.firstChildQuestionId(),
        });
      }
      case CURSOR_TYPE__QUESTION: {
        const {
          questionId,
        } = this;
        if (this.isSection() && !this.isFlat()) {
          return this.child(this.constructor.cursorTypeOnSectionEnter, {
            questionId,
          });
        }
        if (this.isCollection()) {
          return this.child(this.constructor.cursorTypeOnSectionEnter, {
            questionId,
          });
        }
        break;
      }
      default:
      // ...
    }
    return this.end();
  }

  nextChild(current) {
    switch (current.type) {
      case CURSOR_TYPE__ELEMENT: {
        const elementId = this.nextChildElementId(current.elementId);
        if (!elementId) {
          return this.child(this.constructor.cursorTypeOnSectionLeave);
        }
        return this.child(CURSOR_TYPE__ELEMENT, {
          elementId,
        });
      }
      case CURSOR_TYPE__QUESTION: {
        const questionId = this.nextChildQuestionId(current.questionId);
        if (!questionId) {
          if (this.isSection() && !this.isFlat()) {
            return this.child(this.constructor.cursorTypeOnSectionLeave);
          }
          return this.end();
        }
        return this.child(CURSOR_TYPE__QUESTION, {
          questionId,
        });
      }
      case this.constructor.cursorTypeOnSectionEnter: {
        if (this.isSection()) {
          const questionId = this.firstChildQuestionId();
          if (!questionId) {
            return this.child(this.constructor.cursorTypeOnSectionLeave);
          }
          return this.child(CURSOR_TYPE__QUESTION, {
            questionId,
          });
        }
        if (this.isCollection()) {
          const elementId = this.firstChildElementId();
          if (!elementId) {
            return this.child(this.constructor.cursorTypeOnSectionLeave);
          }
          return this.child(CURSOR_TYPE__ELEMENT, {
            elementId,
          });
        }
        break;
      }
      case CURSOR_TYPE__END: {
        return this.child(CURSOR_TYPE__QUESTION, {
          questionId: this.firstChildQuestionId(),
        });
      }
      default:
      // ...
    }
    return this.end();
  }

  static root({
    questionnaire,
    formValues,
    properties,
    stepFilter,
    sortedBy,
    flatSections,
  }) {
    return new this({
      type: CURSOR_TYPE__ROOT,
      questionnaire,
      formValues,
      properties,
      stepFilter,
      sortedBy,
      flatSections,
    });
  }

  static begin(params) {
    // NOTE: next(0) moves to the closest node matching the
    //       default search criteria, which can be overwritten
    //       by params.stepFilter
    return this.lookup(params).next(0);
  }

  static lookup({
    questionId,
    questionnaire,
    formValues,
    properties,
    hierarchy,
    stepFilter,
    sortedBy,
    flatSections,
  }) {
    const stack = hierarchy ? [
      ...hierarchy,
    ] : [];
    return questionnaire.reduceUpToQuestion(
      questionId,
      (currentCursor, question, earlyReturn) => {
        if (
          currentCursor.isFlat() &&
          questionId !== question.id &&
          question.isSection()
        ) {
          return currentCursor;
        }
        let nextCursor = currentCursor.child(CURSOR_TYPE__QUESTION, {
          questionId: question.id,
        });
        if (question.isCollection()) {
          if (stack.length === 0) {
            return earlyReturn(nextCursor);
          }
          const elementId = stack.shift();
          nextCursor = nextCursor.child(CURSOR_TYPE__ELEMENT, {
            elementId,
          });
        }
        return nextCursor;
      },
      {
        initialValue: this.root({
          questionnaire,
          formValues,
          properties,
          stepFilter,
          sortedBy,
          flatSections,
        }),
      },
    );
  }

  static forEach(params, callback) {
    let cursor = this.begin(params);
    while (cursor.isValid()) {
      callback(cursor.question, cursor);
      cursor = cursor.next();
    }
  }

  static map(params, callback) {
    const result = [];
    let cursor = this.begin(params);
    while (cursor.isValid()) {
      result.push(callback(cursor.question, cursor));
      cursor = cursor.next();
    }
    return result;
  }

  static reduce(params, callback, initialValue) {
    let currentValue = initialValue;
    let cursor = this.begin(params);
    while (cursor.isValid()) {
      currentValue = callback(currentValue, cursor.question, cursor);
      cursor = cursor.next();
    }
    return currentValue;
  }

  static filter(params, callback) {
    const result = [];
    let cursor = this.begin(params);
    while (cursor.isValid()) {
      if (callback(cursor.question, cursor)) {
        result.push(cursor.question);
      }
      cursor = cursor.next();
    }
    return result;
  }
}

export default QuestionCursorBase;
