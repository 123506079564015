import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import includes from 'lodash/includes';
import {
  createSelector,
} from 'reselect';
import gql from 'graphql-tag';
import toSelector from '../common/utils/toSelector';

export const ACTION_UPLOAD = '@STAGE/UPLOAD';
export const ACTION_DELETE = '@STAGE/DELETE';
export const ACTION_UPDATE = '@STAGE/UPDATE';

export const STATE_INITIAL = 'INITIAL';
export const STATE_DRAFT = 'DRAFT';
export const STATE_ACTIVE = 'ACTIVE';
export const STATE_ERROR = 'ERROR';
export const STATE_READY = 'READY';

export const getStaged = state => state.stage;

export const isNotCompleted = staged => (answersSheet) => {
  const {
    id,
    state,
  } = answersSheet;
  if (state === 'COMPLETED') {
    return false;
  }
  return (
    !staged[id] ||
    staged[id].state === STATE_DRAFT ||
    staged[id].state === STATE_INITIAL
  );
};

export const isCompleted = staged => (answersSheet) => {
  if (!answersSheet) {
    return false;
  }
  const {
    id,
    state,
  } = answersSheet;
  if (state === 'COMPLETED') {
    return true;
  }
  return (
    staged[id] &&
    staged[id].state !== STATE_DRAFT &&
    staged[id].state !== STATE_INITIAL
  );
};

export const load = selectId => createSelector(
  state => state && state.stage,
  toSelector(selectId),
  (stage, id) => id && stage && stage[id],
);

export const createSelectTranslationId = selectId => createSelector(
  load(selectId),
  rawAnswersSheet => rawAnswersSheet && rawAnswersSheet.translationId,
);

export const createSelectTranslationLanguage = selectId => createSelector(
  load(selectId),
  rawAnswersSheet => rawAnswersSheet && rawAnswersSheet.language,
);

export const save = (
  id,
  {
    version = new Date().toISOString(),
    responses,
    initialBindings,
    previousResponses,
    completionRate,
  },
) => ({
  type: ACTION_UPDATE,
  payload: omitBy(
    {
      version,
      responses,
      initialBindings,
      previousResponses,
      completionRate,
      state: STATE_DRAFT,
    },
    isUndefined,
  ),
  meta: {
    id,
  },
});

export const setTranslation = (id, translationId, language, languages) => ({
  type: ACTION_UPDATE,
  payload: {
    language,
    languages,
    translationId,
  },
  meta: {
    id,
  },
});

export const submit = (id, {
  responses,
}) => ({
  type: ACTION_UPLOAD,
  payload: {
    responses,
  },
  meta: {
    id,
  },
});

const getTimezone = () => {
  try {
    // https://stackoverflow.com/a/44935836/2817257
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    return undefined;
  }
};

export const createMiddleware = ({
  client,
}) => (store) => {
  // https://www.apollographql.com/docs/react/api/apollo-client/#ApolloClient.watchQuery
  const observable = client.watchQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 30 * 1000,
    query: gql`
      query GetAnswersSheets {
        # fetch all answers sheets currently assigned to the recipient
        my {
          id
          answersSheets {
            id
            state
          }
        }
      }
    `,
  });
  observable.subscribe(({
    data,
  }) => {
    const state = store.getState();
    forEach(data.my && data.my.answersSheets, (answersSheet) => {
      const staged = load(answersSheet.id)(state);
      if (staged) {
        if (
          answersSheet.state === 'COMPLETED' &&
          staged.state === STATE_READY
        ) {
          store.dispatch({
            type: ACTION_DELETE,
            meta: {
              id: answersSheet.id,
            },
          });
        }
        if (
          answersSheet.state !== 'COMPLETED' &&
          staged.state === STATE_ERROR
        ) {
          store.dispatch({
            type: ACTION_UPLOAD,
            meta: {
              id: answersSheet.id,
            },
          });
        }
      }
    });
  });

  const retry = (states) => {
    const staged = getStaged(store.getState());
    forEach(staged, ({
      state,
    }, id) => {
      if (includes(states, state)) {
        // NOTE: If anything is active on initial load, it probably
        //       means that the upload was terminated for some reason.
        //       So we put item in "error" state in order to retry.
        store.dispatch({
          type: ACTION_UPDATE,
          payload: {
            state: STATE_ERROR,
            error: 'Upload was not finished',
          },
          meta: {
            id,
          },
        });
      }
    });
  };

  window.addEventListener('online', () => {
    retry([
      STATE_ERROR,
    ]);
  });

  return next => (action) => {
    if (!isPlainObject(action)) {
      return next(action);
    }
    switch (action.type) {
      case 'persist/REHYDRATE': {
        const result = next(action);
        retry([
          STATE_ACTIVE,
          STATE_ERROR,
        ]);
        return result;
      }
      case ACTION_UPLOAD: {
        const id = action.meta && action.meta.id;
        const state = store.getState();
        const payload = {
          ...load(id)(state),
          ...action.payload,
        };
        // NOTE: Let's not forget about pushing the action down the middleware chain,
        //       in order to properly update state.
        next({
          ...action,
          payload,
        });
        return Promise.resolve()
          .then(() => client.mutate({
            mutation: gql`
                mutation PrepareUploadUrl($input: ObtainPreSignedUrlInput) {
                  obtainPreSignedUrl(input: $input)
                }
              `,
            variables: {
              input: {
                answersSheetId: id,
              },
            },
          }))
          .then(({
            data: {
              obtainPreSignedUrl,
            },
          }) => fetch(obtainPreSignedUrl, {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
            },
            body: JSON.stringify({
              encodedResponses: JSON.stringify(payload.responses),
              metadata: {
                userAgent: navigator.userAgent,
                language: payload.language,
                languages: payload.languages,
                timezone: getTimezone(),
              },
            }),
          }))
          .then((response) => {
            if (response.status === 200) {
              store.dispatch({
                type: ACTION_UPDATE,
                payload: {
                  state: STATE_READY,
                  error: null,
                },
                meta: {
                  id,
                },
              });
              return response.text();
            }
            return Promise.reject(new Error(response.statusText));
          })
          .catch((err) => {
            store.dispatch({
              type: ACTION_UPDATE,
              payload: {
                state: STATE_ERROR,
                error: err.toString(),
              },
              meta: {
                id,
              },
            });
          });
      }
      default:
        return next(action);
    }
  };
};

export const reducer = (state = {}, action) => {
  const id = action.meta && action.meta.id;
  if (!id) {
    return state;
  }
  switch (action.type) {
    case ACTION_UPLOAD:
      return {
        ...state,
        [id]: {
          ...state[id],
          ...action.payload,
          error: null,
          state: STATE_ACTIVE,
        },
      };
    case ACTION_UPDATE: {
      return {
        ...state,
        [id]: {
          state: state.state || STATE_INITIAL,
          ...state[id],
          ...action.payload,
        },
      };
    }
    case ACTION_DELETE: {
      if (!state[id]) {
        return state;
      }
      return omit(state, id);
    }
    default:
      return state;
  }
};
