import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import has from 'lodash/has';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';
import checkSchema from './checkSchema';

function cleanValue(valueSchema, value) {
  let cleaned = value;
  if (isPlainObject(valueSchema)) {
    if (has(valueSchema, 'const')) {
      cleaned = valueSchema.const;
    }
    if (typeof valueSchema.type === 'string') {
      switch (valueSchema.type) {
        case 'null': {
          cleaned = null;
          break;
        }
        case 'string': {
          if (typeof cleaned !== 'string') {
            if (!isNil(cleaned) && typeof cleaned.toString === 'function') {
              cleaned = cleaned.toString();
            }
          }
          break;
        }
        case 'integer':
        case 'number': {
          if (typeof cleaned === 'string') {
            const number = +cleaned;
            if (!isNaN(number)) {
              cleaned = number;
            }
          }
          break;
        }
        case 'object': {
          if (isPlainObject(cleaned)) {
            if (isPlainObject(valueSchema.properties)) {
              cleaned = mapValues(cleaned, (v, k) => cleanValue(
                valueSchema.properties[k] || valueSchema.additionalProperties,
                v,
              ));
            }
            if (!valueSchema.additionalProperties) {
              if (valueSchema.properties) {
                cleaned = omitBy(cleaned, (v, k) => !valueSchema.properties[k]);
              } else {
                cleaned = {};
              }
            }
          }
          break;
        }
        case 'array': {
          if (isArray(cleaned)) {
            cleaned = map(cleaned, v => cleanValue(valueSchema.items, v));
          }
          break;
        }
        default:
        // do nothing
      }
    }
    if (valueSchema.type === 'string' && typeof cleaned === 'string') {
      if (
        typeof valueSchema.maxLength === 'number' &&
        value.length > valueSchema.maxLength
      ) {
        cleaned = cleaned.substr(0, valueSchema.maxLength);
      }
    }
    if (valueSchema.type === 'array' && isArray(cleaned)) {
      if (
        typeof valueSchema.maxLength === 'number' &&
        value.length > valueSchema.maxLength
      ) {
        cleaned = cleaned.slice(0, valueSchema.maxLength);
      }
    }
    if (
      has(valueSchema, 'anyOf') &&
      isArray(valueSchema.anyOf) &&
      isPlainObject(cleaned)
    ) {
      const n = valueSchema.anyOf.length;
      if (valueSchema.disjointBy) {
        for (let i = 0; i < n; i += 1) {
          if (
            isPlainObject(valueSchema.anyOf[i]) &&
            valueSchema.anyOf[i].properties &&
            valueSchema.anyOf[i].type === 'object'
          ) {
            const schemaToCheck =
              valueSchema.anyOf[i].properties[valueSchema.disjointBy];
            const error = checkSchema(
              schemaToCheck,
              cleaned[valueSchema.disjointBy],
            );
            if (!error) {
              cleaned = cleanValue(valueSchema.anyOf[i], cleaned);
            }
          }
        }
      }
    }
  }
  return cleaned;
}

export default cleanValue;
