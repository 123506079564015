import isNil from 'lodash/isNil';
import map from 'lodash/map';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import {
  getVersion,
  getIdentifier,
} from '../../utils/versions';
import {
  expand,
} from '../../utils/formValues';
import Question from '../Question';
import QuestionBehavior from '../QuestionBehavior';
import QuestionsHierarchy from '../QuestionsHierarchy';
import {
  parseVariableLiteral,
} from '../../utils/variables';

/**
 * Represents a Questionnaire.
 * @class
 */
class Questionnaire extends QuestionsHierarchy {
  constructor(doc) {
    super(doc, {
      questionIdField: 'id',
      sectionIdField: 'sectionId',
      skipNumberingField: null,
      transform: question => Question.create(question),
    });

    this.behaviors = map(
      doc.behaviors,
      rawBehavior => new QuestionBehavior(rawBehavior),
    );

    this.variables = this.variables || [];

    Object.defineProperty(this, 'variablesById', {
      value: keyBy(this.variables, 'id'),
    });

    const questionsByVariableId = {};
    const groupQuestionsInSection = (sectionId) => {
      this.forEachQuestion(
        (question) => {
          if (!questionsByVariableId[sectionId]) {
            questionsByVariableId[sectionId] = {};
          }
          const id = question.variableId;
          if (id) {
            if (!questionsByVariableId[sectionId][id]) {
              questionsByVariableId[sectionId][id] = {
                questionIds: [],
              };
            }
            questionsByVariableId[sectionId][id].questionIds.push(question.id);
            if (question.isCollection() || question.isComposite()) {
              groupQuestionsInSection(question.id);
            }
          }
        },
        {
          sectionId,
          stopRecursion: q => q.isCollection(),
        },
      );
    };
    groupQuestionsInSection(this.rootSectionId);
    Object.defineProperty(this, 'questionsByVariableId', {
      value: questionsByVariableId,
    });
  }

  getName() {
    return this.name;
  }

  getLanguage() {
    return this.language || 'en';
  }

  getVersion() {
    return getVersion(this._id);
  }

  getIdentifier() {
    return getIdentifier(this._id);
  }

  getDescription() {
    return this.description;
  }

  getQuestionsByVariableId(variableId, sectionId = this.rootSectionId) {
    return (
      this.questionsByVariableId[sectionId] &&
      this.questionsByVariableId[sectionId][variableId] &&
      this.questionsByVariableId[sectionId][variableId].questionIds
    );
  }

  hasVariable(id) {
    return !!this.variablesById[id];
  }

  getDefaultVariableValue(id, {
    expanded = false,
  } = {}) {
    const variable = this.variablesById[id];
    if (variable && variable.defaultValue) {
      const value = parseVariableLiteral(
        variable.valueType,
        variable.defaultValue,
      );
      if (!isNil(value)) {
        return expanded ? expand(value) : value;
      }
    }
    return undefined;
  }

  getDefaultVariables({
    expanded = false,
  } = {}) {
    const variables = {};
    each(this.variables, (variable) => {
      if (variable.defaultValue) {
        try {
          const value = JSON.parse(variable.defaultValue);
          variables[variable.id] = expanded ? expand(value) : value;
        } catch (err) {
          // ...
        }
      }
    });
    return variables;
  }

  presentAnswersSheet(
    answersSheet,
    {
      includeMissing = false,
      filterQuestions = null,
    } = {},
  ) {
    const results = [];
    if (!answersSheet) {
      return results;
    }
    const formValues = answersSheet.toFormValues();
    this.forEachQuestion(
      (question) => {
        const answer = formValues[question.id];
        const formattedAnswer = question.formatAnswer(answer);
        // NOTE: In this case, "nil" means either empty answer or
        //       problematic question type, e.g. collection
        if (includeMissing || !isNil(formattedAnswer)) {
          results.push({
            label: question.getTitle(),
            value: formattedAnswer,
            answer,
            questionType: question.type,
            questionId: question.id,
          });
        }
      },
      {
        stopRecursion: question => question.isCollection(),
        filterQuestions: (question) => {
          if (!question.hasPresentableValue()) {
            return false;
          }
          if (typeof filterQuestions !== 'function') {
            return true;
          }
          return !!filterQuestions(question);
        },
      },
    );
    return results;
  }

  /**
   * If a question belongs to a collection, there can be multiple answers to it,
   * in which case the method will return all of them.
   * @param {String} questionId
   * @param {Object} formValues
   */
  pickAllAnswers(questionId, formValues) {
    const question = this.getQuestionById(questionId);
    if (!question) {
      return [];
    }
    const lookupIds = [
      ...this.getParentIdsWhere(questionId, parent => parent.isCollection()),
      questionId,
    ];
    const answers = [];
    const traverse = (currentFormValues, currentLookupIds) => {
      const answer =
        currentFormValues && currentFormValues[currentLookupIds[0]];
      if (currentLookupIds.length === 1) {
        if (answer) {
          answers.push(answer);
        }
      } else if (answer && answer._elementsOrder && answer._elements) {
        // NOTE: We know in advance that corresponding question is a collection, because
        //       that's how we've chosen lookupIds above.
        each(answer._elementsOrder, (elementId) => {
          traverse(
            answer._elements[elementId] &&
              answer._elements[elementId]._elements,
            currentLookupIds.slice(1),
          );
        });
      }
    };
    traverse(formValues, lookupIds);
    return answers;
  }

  /**
   * Group all behavior actions outcomes by id of the question to which they correspond to.
   */
  groupMutationsByQuestionId() {
    const mutationsByQuestionId = {};
    each(this.behaviors, (behavior) => {
      const formula = behavior.createFormula();
      const context = {
        questionnaire: this,
      };
      each(behavior.thenActions, (action) => {
        // NOTE: This is important for "skipToQuestion", were
        //       we require both start and end to be in the same scope.
        if (!action.validate(context)) {
          return;
        }
        const mutations = action.doSelf({
          questionnaire: this,
        });
        each(mutations, ({
          questionId,
          transform,
        }) => {
          if (!mutationsByQuestionId[questionId]) {
            mutationsByQuestionId[questionId] = [];
          }
          mutationsByQuestionId[questionId].push({
            formula,
            transform,
            actionType: action.type,
          });
        });
      });
    });
    return mutationsByQuestionId;
  }

  /**
   * Enhance an array of raw responses objects (only questionId and answer) with
   * useful metadata, before storing the responses at the database.
   * @param {Object[]} rawResponses
   * @param {Object} [options]
   * @param {Date} [options.savedAt]
   */
  decorateRawResponses(rawResponses, {
    savedAt = new Date(),
  } = {}) {
    const responses = [];
    each(rawResponses, ({
      questionId,
      hierarchyKey,
      answer,
      whyEmpty,
    }) => {
      const question =
        this.getQuestionById(questionId) ||
        Question.createUnknown({
          id: questionId,
        });
      if (question) {
        const response = question.createResponse(answer, {
          hierarchyKey,
          whyEmpty,
          savedAt,
        });
        responses.push(response);
      }
    });
    return responses;
  }

  enhanceWithQuestionType(responses) {
    const newResponses = [];
    each(responses, (response) => {
      const question = this.getQuestionById(response.questionId);
      if (!question) {
        return;
      }
      newResponses.push({
        ...response,
        questionType: question.type,
      });
    });
    return newResponses;
  }

  createResponsesFromFormValues(
    formValues,
    {
      responses = [],
      sectionId,
      hierarchyKey,
      ...other
    } = {},
  ) {
    if (isEmpty(formValues)) {
      return responses;
    }
    this.forEachQuestion(
      (question) => {
        const {
          _meta,
          _elements,
          _elementsOrder,
          ...answer
        } =
          formValues[question.id] || {};
        if (question.isCollection()) {
          if (isEmpty(_elementsOrder)) {
            return;
          }
          responses.push(
            question.createResponse(
              {
                value: _elementsOrder,
              },
              {
                ...other,
                hierarchyKey,
              },
            ),
          );
          each(_elementsOrder, (elementId) => {
            this.createResponsesFromFormValues(
              _elements &&
                _elements[elementId] &&
                _elements[elementId]._elements,
              {
                responses,
                sectionId: question.id,
                hierarchyKey: hierarchyKey
                  ? `${hierarchyKey}.${question.id}.${elementId}`
                  : `${question.id}.${elementId}`,
                ...other,
              },
            );
          });
        } else {
          if (Question.isEmptyAnswer(answer)) {
            return;
          }
          responses.push(
            question.createResponse(answer, {
              ...other,
              hierarchyKey,
            }),
          );
        }
      },
      {
        sectionId,
        stopRecursion: q => q.isCollection(),
      },
    );
    return responses;
  }

  createOnePageRawCopy({
    layout,
    rootSectionId = this.rootSectionId,
  }) {
    const rawQuestionnaire = {
      ...this,
      rootSectionId,
    };
    rawQuestionnaire.questions = this.mapQuestions(
      question => ({
        ...question,
      }),
      {
        sectionId: rootSectionId,
      },
    );
    rawQuestionnaire.behaviors = this.behaviors.map(behavior => ({
      ...behavior,
    }));
    rawQuestionnaire.variables = this.variables.map(variable => ({
      ...variable,
    }));
    const oneScreen = {
      layout,
      id: this._id,
      title: this.name,
      description: this.description,
      questions: this.mapQuestions(
        question => ({
          id: question.id,
          label: question.getLabel(),
        }),
        {
          sectionId: rootSectionId,
          stopRecursion: q => q.isCollection(),
        },
      ),
    };
    rawQuestionnaire.screens = [
      oneScreen,
    ];
    return rawQuestionnaire;
  }

  createOnePageCopy(...args) {
    return new this.constructor(this.createOnePageRawCopy(...args));
  }

  getNavigation({
    currentQuestionId,
    questionsIdsAnsweredSoFar = [],
  }) {
    const questionsInHierarchy = this.getAllQuestionsInHierarchy([
      currentQuestionId,
      ...questionsIdsAnsweredSoFar,
    ]);
    const levels = [];
    const getChildren = (parentId, {
      minLength = 0,
    } = {}) => {
      const children = this.getChildQuestions(parentId).filter(
        child => !!questionsInHierarchy[child.id] && child.isVisible(),
      );
      if (children && children.length >= minLength) {
        return children.map(({
          id,
          label,
          number,
        }) => ({
          questionId: id,
          label: label ? `${number}. ${label}` : number,
        }));
      }
      return undefined;
    };
    const children = getChildren(currentQuestionId, {
      minLength: 1,
    });
    if (children) {
      levels.unshift({
        parentId: currentQuestionId,
        children,
      });
    }
    let question = this.getQuestionById(currentQuestionId);
    let parentId = question.sectionId;
    while (question && parentId) {
      levels.unshift({
        parentId,
        questionId: question.id,
        label: question.number,
        children: getChildren(parentId, {
          minLength: 2,
        }),
      });
      question = this.getQuestionById(parentId);
      parentId = question && question.sectionId;
    }
    return {
      levels,
    };
  }

  getReference() {
    return {
      id: this._id,
      name: this.name,
    };
  }
}

Questionnaire.collection = 'Questionnaires';

export default Questionnaire;
