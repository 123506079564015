import mapValues from 'lodash/mapValues';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import isNumber from 'lodash/isNumber';
import BaseModel from '../BaseModel';
import CurrentUserSelect from '../../selectors/CurrentUser';
import {
  toYearMonthDay,
  toDateTimeString,
  getCurrentYearMonthDay,
  getCurrentDateTimeString,
} from '../../utils/date';
import {
  FORMULA_TYPE__UNKNOWN,
  FORMULA_TYPE__UNARY,
  FORMULA_CONTEXT_PROPERTY__CURRENT_USER_ID,
  FORMULA_CONTEXT_PROPERTY__CURRENT_USER_NAME,
  FORMULA_CONTEXT_PROPERTY__CURRENT_USER_JOB_TITLE,
  FORMULA_CONTEXT_PROPERTY__CURRENT_DATE_TIME,
  FORMULA_CONTEXT_PROPERTY__CURRENT_DATE,
  FORMULA_OPPOSITES,
} from '../../constants';
import {
  selectClockAsTimestamp,
} from '../../utils/clock';

const constant = x => () => x;
const identity = x => x;

export default class Formula extends BaseModel {
  constructor(doc) {
    super(doc);
    this.settings = this.settings || {};

    const error = this.validate();
    if (error) {
      Object.defineProperties(this, {
        evaluate: {
          value: constant({
            error,
          }),
        },
        toMongoExpression: {
          value: constant({
            $literal: `[ERR: ${error.message}]`,
          }),
        },
      });
    }
  }

  validate() {
    return this.constructor.NotImplemented;
  }

  evaluate() {
    return {
      error: this.constructor.NotImplemented,
    };
  }

  getPossibleOutcomes() {
    return this.meta && this.meta.possibleOutcomes;
  }

  toMongoExpression() {
    return {
      $literal: `[ERR: ${this.constructor.NotImplemented.message}]`,
    };
  }

  compile() {
    return {
      ...this,
    };
  }

  toRawFormula() {
    return this.raw;
  }

  remap(mapQuestionId) {
    return new this.constructor(
      mapValues(this, (value, key) => {
        switch (key) {
          case 'settings': {
            return value
              ? mapValues(
                value,
                this.constructor.createMapSettings(mapQuestionId),
              )
              : value;
          }
          case 'meta': {
            return value
              ? mapValues(value, this.constructor.createMapMeta(mapQuestionId))
              : value;
          }
          default:
            return value;
        }
      }),
    );
  }

  static create(doc = {}) {
    let constructor = this.types[doc.type];
    if (!constructor) {
      const oppositeType = FORMULA_OPPOSITES[doc.type];
      if (oppositeType) {
        const Opposite = this.types[oppositeType];
        if (Opposite) {
          return this.types[FORMULA_TYPE__UNARY].not(
            new Opposite({
              ...doc,
              type: oppositeType,
            }),
          );
        }
      }
      constructor = this.types[FORMULA_TYPE__UNKNOWN];
    }
    return new constructor(doc);
  }

  static createUnknown(doc = {}) {
    return new this.types[FORMULA_TYPE__UNKNOWN](doc);
  }

  static createContextSelector({
    utcOffset,
  } = {}) {
    return createStructuredSelector({
      // TODO: When we have more than three current user properties, let's make it
      //       more scalable by allowing to choose any (known) user property.
      [FORMULA_CONTEXT_PROPERTY__CURRENT_USER_ID]: createSelector(
        CurrentUserSelect.user(),
        currentUser => currentUser && {
          value: currentUser._id,
        },
      ),
      [FORMULA_CONTEXT_PROPERTY__CURRENT_USER_NAME]: createSelector(
        CurrentUserSelect.user(),
        currentUser => currentUser && {
          value: currentUser.getFullName(),
        },
      ),
      [FORMULA_CONTEXT_PROPERTY__CURRENT_USER_JOB_TITLE]: createSelector(
        CurrentUserSelect.user(),
        currentUser => currentUser && {
          value: currentUser.getJobTitle(),
        },
      ),
      [FORMULA_CONTEXT_PROPERTY__CURRENT_DATE]: createSelector(
        selectClockAsTimestamp,
        ts => (isNumber(ts)
          ? {
            value: toYearMonthDay(new Date(ts), utcOffset),
          }
          : {
            value: getCurrentYearMonthDay(utcOffset),
          }),
      ),
      [FORMULA_CONTEXT_PROPERTY__CURRENT_DATE_TIME]: createSelector(
        selectClockAsTimestamp,
        ts => (isNumber(ts)
          ? {
            value: toDateTimeString(new Date(ts), utcOffset),
          }
          : {
            value: getCurrentDateTimeString(utcOffset),
          }),
      ),
    });
  }

  static createMapSettings() {
    return identity;
  }

  static createMapMeta() {
    return identity;
  }
}

Formula.NotImplemented = {
  message: 'The formula was not implemented',
};

Formula.NotConfigured = {
  message: 'The formula lacks proper configuration',
};

Formula.UnknownOperator = {
  message: 'Unknown operator',
};

Formula.NoQuestionnaire = {
  message: 'Missing questionnaire',
};

Formula.NoData = {
  message: 'Missing information',
};

Formula.BadContext = {
  message: 'Formula cannot be evaluated in this context',
};

Formula.types = {};
