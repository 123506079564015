import map from 'lodash/map';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import evaluateFormValuesAndProperties from './evaluateFormValuesAndProperties';
import EvaluationScope from '../../models/EvaluationScope';
import {
  toFormValues,
  toResponsesMap,
  filterResponses,
} from '../responses';

/**
 * Remove all responses that corresponds to hidden questions.
 *
 * Note that this may be an iterative process, because hiding one response may result in hiding another one etc.
 * We only stop the result stabilizes.
 *
 * @param {Questionnaire} questionnaire
 * @param {Object[]} responses
 * @returns {Object[]}
 */
function removeResponsesToHiddenQuestions(questionnaire, responses) {
  // NOTE: This is a simplification, because evaluation scope will not contain
  //       some details about the context, but this should not be relevant
  //       from the perspective of behaviors.
  const evaluationScope = new EvaluationScope({
    questionnaire,
    answers: toFormValues(responses, {
      includeMetaAnswer: true,
    }),
  });
  const {
    formValues,
  } = evaluateFormValuesAndProperties(evaluationScope);
  const responsesMap = toResponsesMap(formValues);

  const newResponses = map(responses, (response) => {
    const {
      answer,
      questionId,
      hierarchyKey,
    } = response;
    const key = hierarchyKey ? `${hierarchyKey}.${questionId}` : questionId;
    if (!responsesMap[key]) {
      return null;
    }
    return {
      ...response,
      answer: answer && pick(answer, keys(responsesMap[key].answer)),
    };
  });

  // Remove all "nulls" and "lost in hierarchy" responses.
  return filterResponses(newResponses);
}

export default removeResponsesToHiddenQuestions;
