import moment from 'moment';
import {
  YEAR_MONTH_DAY,
  DATE_TIME_FORMAT,
} from '../constants/date';
import {
  zoneToTimestamp,
} from './zone';

export function toYearMonthDay(date, utcOffset) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format(YEAR_MONTH_DAY);
}

export function getCurrentYearMonthDay(utcOffset) {
  return toYearMonthDay(new Date(), utcOffset);
}

export function toHoursMinutes(date, utcOffset) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format('HH:mm');
}

export function getCurrentHoursMinutes(utcOffset) {
  return toHoursMinutes(new Date(), utcOffset);
}

export function toDateTimeString(date, utcOffset) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format(DATE_TIME_FORMAT);
}

export function getCurrentDateTimeString(utcOffset) {
  return toDateTimeString(new Date(), utcOffset);
}

/**
 * Checks if the given date string contains utcOffset information.
 * @param {String} isoString
 * @returns {Boolean}
 */
export function hasUtcOffset(isoString) {
  return (
    /\+\d\d:\d\d$/.test(isoString) ||
    /\+\d\d\d\d$/.test(isoString) ||
    /-\d\d:\d\d$/.test(isoString) ||
    /-\d\d\d\d$/.test(isoString)
  );
}

/**
 * Find a timestamp that corresponds to the provided string representation
 * in the specified timezone. If there's no exact-match, return an approximate value.
 * @param {string} isoString
 * @param {string} [zone='utc']
 * @return {Date}
 */
export function parseLocalTime(isoString, zone = 'utc') {
  const utcDate = moment.utc(isoString, moment.ISO_8601).toDate();
  return zoneToTimestamp(zone, {
    // if the exact time does not exist in that zone (e.g. due to DST),
    // return a value that is at least close enough ...
    noStrict: true,
  })(utcDate);
}
