export const MINIMAL_PASSWORD_LENGTH = 8;
export const MIN_HEARTBEAT_DELAY_IN_SECONDS = 60;
export const MAX_FAILED_LOGIN_ATTEMPTS = 5;
export const ERROR_ACCOUNT_LOCKED = 'account.locked';
export const ERROR_ACCOUNT_LOCKED_UI_MESSAGE__STRING = `
  The account has been blocked. Please contact your system administrator to unblock your account.
`;

export const USER_SESSION_KEEP_ALIVE_SECONDS = 30;

export const USER_PROPERTY__NAME = 'name';
export const USER_PROPERTY__JOB_TITLE = 'jobTitle';

export const USER_PROPERTIES = [
  USER_PROPERTY__NAME,
  USER_PROPERTY__JOB_TITLE,
];

export const USER_PROPERTY_OPTIONS = [
  {
    value: USER_PROPERTY__NAME,
    label: 'Full name',
  },
  {
    value: USER_PROPERTY__JOB_TITLE,
    label: 'Job title',
  },
];
