import every from 'lodash/every';
import includes from 'lodash/includes';
import {
  toYearMonthDay,
} from '../utils/date';
import BaseModel from './BaseModel';
import {
  BROWSE_RECIPIENTS,
} from '../permissions';
import CalendarDate from './CalendarDate';

class ProjectFilter extends BaseModel {
  getPermissions() {
    if (this.restrictedTo) {
      return [
        {
          key: BROWSE_RECIPIENTS,
          tier: this.restrictedTo,
        },
      ];
    }
    return [];
  }

  getStart() {
    if (this.state.dateStart) {
      return new Date(this.state.dateStart.yearMonthDay);
    }
    return undefined;
  }

  getEnd() {
    if (this.state.dateEnd) {
      return new Date(this.state.dateEnd.yearMonthDay);
    }
    return undefined;
  }

  formatDateStart() {
    return (
      this.state.dateStart &&
      new CalendarDate(this.state.dateStart).toMoment().format('MMM Do YY')
    );
  }

  formatDateEnd() {
    return (
      this.state.dateEnd &&
      new CalendarDate(this.state.dateEnd).toMoment().format('MMM Do YY')
    );
  }

  includesTags(tags) {
    return every(tags, tag => includes(this.tags, tag));
  }

  static createDateRangeState(start, end) {
    return {
      dateStart: {
        yearMonthDay: toYearMonthDay(start),
      },
      dateEnd: {
        yearMonthDay: toYearMonthDay(end),
      },
    };
  }
}

export default ProjectFilter;
