import i18next from 'i18next';
import {
  createSelector,
} from 'reselect';
import {
  registerStore,
} from '../common/utilsClient/multiReducer';
import {
  supportedLanguages,
  maybeUpdateTextDirection,
  getNavigatorLanguage,
  getPreferredLanguage,
} from '../utils/i18next';
import getNewLanguagePreference from '../utils/getNewLanguagePreference';

const store = registerStore({
  path: 'preferences',
  initialState: {
    languagePreference: [
      getNavigatorLanguage(),
    ],
    fontSize: 16,
    animationSpeed: 400,
    theme: null,
  },
});

export const getAnimationSpeed = store.get('animationSpeed');
export const selectLanguagePreference = store.get('languagePreference');
export const createSelectPreferredLanguage = availableLanguages => createSelector(
  selectLanguagePreference,
  languagePreference => getPreferredLanguage(languagePreference, availableLanguages),
);

export const setLanguagePreference = store.create.set('languagePreference');
export const selectPreferredUiLanguage = createSelectPreferredLanguage(
  supportedLanguages,
);

export const setPreferredLanguage = (
  newPreferredLanguage,
  availableLanguages,
) => (dispatch, getState) => {
  const state = getState();
  const oldLanguagePreference = selectLanguagePreference(state);
  const newLanguagePreference = getNewLanguagePreference(
    oldLanguagePreference,
    newPreferredLanguage,
    availableLanguages,
  );
  dispatch(setLanguagePreference(newLanguagePreference));
};

export const createMiddleware = () => {
  return ({
    getState,
  }) => {
    //---------------------------------------
    const handleLanguageChange = (value) => {
      i18next.changeLanguage(value);
      maybeUpdateTextDirection(value);
    };
    return next => (action) => {
      const previousValue = selectPreferredUiLanguage(getState());
      const result = next(action);
      const currentValue = selectPreferredUiLanguage(getState());
      if (previousValue !== currentValue) {
        handleLanguageChange(currentValue, previousValue);
      }
      return result;
    };
  };
};

export default store;
