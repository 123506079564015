import {
  isEmptyAnswer,
} from '../../../utils/question';
import Formula from '../Formula';
import {
  FORMULA_TYPE__ANSWERS_SHEET_PROPERTY,
  ANSWERS_SHEET_PROPERTY_DEFINITIONS,
} from '../../../constants';

export class FormulaAnswersSheetProperty extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.id) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const data = scope.getAnswersSheetProperty(this.settings.id);
    if (isEmptyAnswer(data)) {
      return {
        error: this.constructor.NoData,
      };
    }
    return {
      value: data.value,
    };
  }

  toMongoExpression() {
    const key = this.constructor.getPropertyKey(this.settings.id);
    if (key) {
      return `$${key}`;
    }
    return {
      $literal: '[unknown]',
    };
  }

  static getPropertyKey(id) {
    const property = ANSWERS_SHEET_PROPERTY_DEFINITIONS[id];
    return property && property.key;
  }

  static getPropertyType(id) {
    const property = ANSWERS_SHEET_PROPERTY_DEFINITIONS[id];
    return property && property.type;
  }
}

Formula.types[
  FORMULA_TYPE__ANSWERS_SHEET_PROPERTY
] = FormulaAnswersSheetProperty;
