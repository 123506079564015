import React from 'react';
import Layout from '../../common/components/LayoutMaster';
import Header from './Header';
import Activity from '../../components/Activity';

const LandingSkeleton = () => {
  return (
    <Layout
      headerChildren={<Header loading />}
      isCentered
    >
      <Activity loading />
    </Layout>
  );
};

export default LandingSkeleton;
