import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const Component = styled.div`
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => theme(`measure.${props.max}`)(props)};

  ${props => `
    ${
  props.gutter
    ? `
      padding-right: ${theme(`space.${props.gutter}`)(props)};
      padding-left: ${theme(`space.${props.gutter}`)(props)};
    `
    : ''
};

    ${
  props.intrinsic
    ? `
      display:flex;
      flex-direction: column;
      align-items: center;
    `
    : ''
};

    ${
  props.isStretched
    ? `
      width: 100%;
    `
    : ''
};
  `};
`;

const Center = ({
  className,
  children,
  max,
  gutter,
  intrinsic,
  isStretched,
}) => (
  <Component
    className={className}
    max={max}
    gutter={gutter}
    intrinsic={intrinsic}
    isStretched={isStretched}
  >
    {children}
  </Component>
);

Center.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  gutter: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
  ]),
  // TODO: Change max to something different as it gets passes to the DOM
  max: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  intrinsic: PropTypes.bool,
  isStretched: PropTypes.bool,
};

Center.defaultProps = {
  className: null,
  children: null,
  // TODO: Make space / gutter strings
  gutter: 0,
  max: 4,
  intrinsic: false,
  isStretched: false,
};

export default Center;
