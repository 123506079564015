import {
  createEntitiesSelectors,
} from '@theclinician/ddp-connector/lib/selectors';
import EJSON from '../utils/ejson';

const createSelectors = (Model, plugins) => {
  const selectors = createEntitiesSelectors(Model.collection, {
    Model,
    prefix: Model.store,
    plugins,
    transform: doc => EJSON.fromJSONValue(doc),
  });
  if (!Model.collection) {
    throw new Error(
      `Cannot create selectors for model: "${Model.name}" because it does not have collection property`,
    );
  }
  return selectors;
};

export default createSelectors;
