import {
  QUESTION_TYPE__RAW_JSON,
  QUESTION_TYPE__BLUETOOTH_DEVICE,
} from '../../../constants';
import Question from '../Question';
import checkSchema, {
  getErrorMessage,
} from '../../../utils/checkSchema';

export default class QuestionJSONString extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__RAW_JSON;
  }

  get jsonSchema() {
    let jsonSchema;
    try {
      jsonSchema = JSON.parse(this.settings.jsonSchemaString);
    } catch (err) {
      jsonSchema = false; // means: accept nothing ...
    }
    Object.defineProperty(this, 'jsonSchema', {
      value: jsonSchema,
    });
    return jsonSchema;
  }

  getValueError(value, valueType) {
    const error = super.getValueError(value, valueType);
    if (error) {
      return error;
    }
    if (value) {
      let jsonValue;
      try {
        jsonValue = JSON.parse(jsonValue);
      } catch (err) {
        return `JSON.parse error: ${err.toString()}`;
      }
      const schemaError = checkSchema(this.jsonSchema, jsonValue);
      if (schemaError) {
        return getErrorMessage(schemaError);
      }
    }
    return null;
  }
}

QuestionJSONString.expectedValueType = {
  type: 'string',
};
Question.types[QUESTION_TYPE__RAW_JSON] = QuestionJSONString;
Question.types[QUESTION_TYPE__BLUETOOTH_DEVICE] = QuestionJSONString;
