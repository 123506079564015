import {
  ANSWER_VALUE_MAX_LENGTH,
  QUESTION_TYPE__COLLECTION,
} from '../../../constants';
import Question from '../Question';

export default class QuestionCollection extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__COLLECTION;
  }

  getRawKeys() {
    return [
      'value',
    ];
  }

  getValueError(value, valueType) {
    const error = super.getValueError(value, valueType);
    if (error) {
      return error;
    }
    if (value) {
      for (let i = 0; i < value.length; i += 1) {
        if (value[i].length > ANSWER_VALUE_MAX_LENGTH) {
          return `Value too long, expected ${ANSWER_VALUE_MAX_LENGTH} got ${value.length}`;
        }
      }
    }
    return null;
  }
}

QuestionCollection.expectedValueType = {
  type: 'array',
  items: {
    type: 'string',
  },
};

QuestionCollection.editorSettings = {};

Question.types[QUESTION_TYPE__COLLECTION] = QuestionCollection;
