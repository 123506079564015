import isPlainObject from 'lodash/isPlainObject';
import Questionnaire from '../../models/Questionnaire';

export const ACTION_SET_VALUE = '@QUESTIONNAIRE/SET_VALUE';
export const ACTION_ENTER_FORM_VALUES = '@QUESTIONNAIRE/ENTER_FORM_VALUES';
export const ACTION_INSERT_ELEMENT = '@QUESTIONNAIRE/INSERT_ELEMENT';
export const ACTION_REMOVE_ELEMENT = '@QUESTIONNAIRE/REMOVE_ELEMENT';
export const ACTION_INITIALIZE = '@QUESTIONNAIRE/INITIALIZE';
export const ACTION_CLEAR = '@QUESTIONNAIRE/CLEAR';
export const ACTION_CLEAR_ALL = '@QUESTIONNAIRE/CLEAR_ALL';
export const ACTION_SYNC_FORM_VALUES = '@QUESTIONNAIRE/SYNC_FORM_VALUES';
export const ACTION_FORCE_AUTOSAVE = '@QUESTIONNAIRE/FORCE_AUTOSAVE';
export const ACTION_VALIDATE_QUESTIONNAIRE =
  '@QUESTIONNAIRE/VALIDATE_QUESTIONNAIRE';
export const ACTION_REPLACE_FORM_VALUES = '@QUESTIONNAIRE/REPLACE_FORM_VALUES';
export const ACTION_REPLACE_FORM_ERRORS = '@QUESTIONNAIRE/REPLACE_FORM_ERRORS';

export const initializeQuestionnaire = (name, initialValues) => ({
  type: ACTION_INITIALIZE,
  payload: initialValues,
  meta: {
    name,
  },
});

export const clearQuestionnaire = name => ({
  type: ACTION_CLEAR,
  meta: {
    name,
  },
});

export const clearAllQuestionnaires = () => ({
  type: ACTION_CLEAR_ALL,
});

export const syncFormValues = (name, formValues, meta) => {
  if (formValues && !isPlainObject(formValues)) {
    throw Error(`Expected plain object or null, got ${typeof formValues}`);
  }
  return {
    type: ACTION_SYNC_FORM_VALUES,
    payload: formValues,
    meta: {
      ...meta,
      name,
    },
  };
};

export const enterFormValues = (name, formValues, meta) => {
  if (!isPlainObject(formValues)) {
    throw Error(`Expected plain object, got ${typeof formValues}`);
  }
  return {
    type: ACTION_ENTER_FORM_VALUES,
    payload: formValues,
    meta: {
      ...meta,
      name,
    },
  };
};

export const forceAutosave = name => ({
  type: ACTION_FORCE_AUTOSAVE,
  meta: {
    name,
  },
});

export const validateWithQuestionnaire = (name, value, meta) => {
  let rawQuestionnaire;
  if (isPlainObject(value)) {
    rawQuestionnaire = value;
  } else if (value instanceof Questionnaire) {
    rawQuestionnaire = value.raw;
  } else if (value) {
    throw Error(`Expected plain object or Questionnaire, got ${typeof value}`);
  }
  return {
    type: ACTION_VALIDATE_QUESTIONNAIRE,
    meta: {
      ...meta,
      rawQuestionnaire,
      name,
    },
  };
};

export const replaceFormValues = (name, formValues = {}) => ({
  type: ACTION_REPLACE_FORM_VALUES,
  payload: formValues,
  meta: {
    name,
  },
});

export const replaceFormErrors = (name, formErrors = {}) => ({
  type: ACTION_REPLACE_FORM_ERRORS,
  payload: formErrors,
  meta: {
    name,
  },
});
