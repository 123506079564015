import styled from 'styled-components/macro';
import React from 'react';
import SelectAntd from 'antd/es/select';
import 'antd/es/select/style/css';
import {
  theme,
} from '../utilsClient/cssHelpers';

const Component = styled(SelectAntd)`
  && {
    &,
    .ant-select-arrow {
      color: ${theme('color.onSurface')};
    }

    .ant-select-selection {
      background-color: ${theme('color.surface')};
      border-color: ${theme('color.border')};
    }
  }
`;

const StyledSelect = (props) => {
  return <Component {...props} />;
};

export default SelectAntd;

export {
  StyledSelect,
};
