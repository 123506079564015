import PropTypes from 'prop-types';
import each from 'lodash/each';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
import {
  createSelector,
  createStructuredSelector,
  defaultMemoize,
} from 'reselect';
import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  compose,
} from 'recompose';
import Context, {
  withContext,
} from './QuestionnaireContext';
import SelectorsHub from './SelectorsHub';
import {
  constant,
  argument,
  property,
  identity,
  higherOrderSelector,
} from '../../utilsClient/selectors';
import Model from '../../models/Question';
import Formula from '../../models/Formula';
import Random from '../../utils/random';
import {
  strToBase64,
} from '../../utils/base64';
import {
  ValueSetPropTypes,
} from './propTypes';

const getRandomisedChoices = (choices, question, answersSheetId) => {
  const order = new Map();
  let seed = 1;

  each(choices, (choice) => {
    if (!question) {
      return;
    }

    seed += reduce(answersSheetId, (sum, c) => sum + c.charCodeAt(0), 0);
    seed += reduce(question.id, (sum, c) => sum + c.charCodeAt(0), 0);

    const getRandomNumber = () => {
      const x = Math.sin(seed) * 10000;

      return x - Math.floor(x);
    };

    order.set(choice, getRandomNumber());
  });

  return sortBy(choices, choice => order.get(choice));
};

class Question extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.getContext = createSelector(
      argument(0, 'context'),
      argument(1), // question
      (context, question) => {
        const newContext = Object.assign(Object.create(context), {
          question,
          questionId: question.id,
        });
        return newContext;
      },
    );

    this.handleAppend = this.handleAppend.bind(this);
  }

  handleAppend() {
    const {
      dispatch,
      question,
      context,
    } = this.props;
    if (!question) {
      return;
    }
    if (!question.isCollection()) {
      return;
    }
    if (dispatch) {
      dispatch(context.insertElement(Random.id(), question.id));
    }
  }

  render() {
    const {
      id,
      children,
      elements,
      question,
      disabled,
      valueSet,
      choices,
      iframeUrl,
      contextOptions,
      ...other
    } = this.props;
    if (!question) {
      return null;
    }
    const childrenProps = {
      ...other,
      elements,
      question,
      valueSet,
      choices,
      iframeUrl,
      questionId: id,
      handleChange: this.handleChange,
      handleAppend: this.handleAppend,
    };
    // NOTE: Can either be disabled explicitly,
    //       via context property, or it can be
    //       a direct question property.
    if (
      disabled ||
      (contextOptions && contextOptions.disabled) ||
      question.shouldDisableInput()
    ) {
      childrenProps.disabled = true;
    }
    return (
      <Context.Provider value={this.getContext(this.props, question)}>
        {children(childrenProps)}
      </Context.Provider>
    );
  }
}

Question.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  dispatch: PropTypes.func,
  elements: PropTypes.arrayOf(PropTypes.string),
  question: PropTypes.instanceOf(Model),
  valueSet: ValueSetPropTypes,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ).isRequired,
  iframeUrl: PropTypes.string,
  context: PropTypes.instanceOf(SelectorsHub).isRequired,
  disabled: PropTypes.bool,
  contextOptions: PropTypes.objectOf(PropTypes.any),
};

Question.defaultProps = {
  dispatch: null,
  elements: null,
  question: null,
  valueSet: null,
  iframeUrl: null,
  disabled: null,
  contextOptions: null,
};

export default compose(
  withContext(),
  connect(() => {
    const selectQuestionnaire = higherOrderSelector(
      property('context'),
      context => context.select.questionnaire(),
    );
    const selectOriginalQuestion = createSelector(
      property('id'),
      selectQuestionnaire,
      (id, questionnaire) => questionnaire && questionnaire.getQuestionById(id),
    );
    const selectQuestionProperties = higherOrderSelector(
      property('context'),
      property('id'),
      (context, id) => context.createPropertiesSelector(id),
    );
    const selectAnswersSheetId = createSelector(
      higherOrderSelector(property('context'), context => context.select.meta()),
      meta => meta && meta.answersSheetId,
    );
    const selectQuestion = createSelector(
      selectQuestionProperties,
      selectOriginalQuestion,
      (properties, question) => {
        if (!question) {
          return question;
        }
        return Object.assign(Object.create(question), properties);
      },
    );
    const selectValueSet = createSelector(
      createSelector(
        (state, props) => props.context.select.evaluationScope(props.context.scopeKey)(state),
        selectOriginalQuestion,
        (evaluationScope, question) => {
          if (question && question.shouldEvaluateValueSet()) {
            // NOTE: The second alternative is only used for compatibility
            //       with older versions of project wizard. It will be removed
            //       as soon as project wizard is migrated.
            const formula =
              question.settings &&
              (question.settings.valueSetFormula || question.settings.formula);
            if (!formula) {
              return null;
            }
            const result = Formula.create(formula).evaluate(evaluationScope);
            if (!result.error) {
              return result.value;
            }
          }
          return null;
        },
      ),
      defaultMemoize(identity, isEqual),
    );
    const selectQuestionChoices = createSelector(
      selectQuestion,
      selectValueSet,
      selectAnswersSheetId,
      (question, valueSet, answersSheetId) => {
        if (!question) {
          return [];
        }
        const choices = question.getChoices(valueSet);
        if (question.useRandomisedResponsesOrder()) {
          return getRandomisedChoices(choices, question, answersSheetId);
        }
        return choices;
      },
    );
    const selectIframeUrl = createSelector(
      higherOrderSelector(property('context'), context => context.select.formValues()),
      selectQuestion,
      (formValues, question) => {
        if (!question || !question.isIframe()) {
          return null;
        }
        const {
          url,
          query,
        } = question.getIframeParams(formValues);
        if (query) {
          return `${url}?q=${strToBase64(JSON.stringify(query))}`;
        }
        return url;
      },
    );
    return createStructuredSelector({
      question: selectQuestion,
      valueSet: selectValueSet,
      choices: selectQuestionChoices,
      iframeUrl: selectIframeUrl,
      elements: higherOrderSelector(
        property('id'),
        property('context'),
        selectQuestionnaire,
        (id, context, questionnaire) => {
          const question = questionnaire && questionnaire.getQuestionById(id);
          if (!question || !question.isCollection()) {
            return constant(null);
          }
          return context.createFormValueSelector(`${id}._elementsOrder`);
        },
      ),
      contextOptions: higherOrderSelector(property('context'), context => context.select.options()),
    });
  }),
)(Question);
