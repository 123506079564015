import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import {
  QUESTION_TYPE__SCALE,
} from '../constants';

export function isNilValue(value) {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (isArray(value) && value.length === 0)
  );
}

export function hasNoElements({
  _elements,
  _elementsOrder,
}) {
  if (_elementsOrder) {
    return isEmpty(_elementsOrder);
  }
  return isEmpty(_elements);
}

export function isEmptyAnswer(answer) {
  if (!answer) {
    return true;
  }
  return hasNoElements(answer) && isNilValue(answer.value);
}

export function parseValueExpr(value) {
  // NOTE: In the future we may want to use
  //       a more sophisticated parser.
  if (value === true) {
    return 1;
  }
  if (value === false) {
    return 0;
  }
  return +value;
}

export function isEmptyAnswerAsMongoExpression(id) {
  return {
    $or: [
      {
        $eq: [
          `${id}.value`,
          null,
        ],
      },
      {
        $eq: [
          `${id}.value`,
          '',
        ],
      },
      {
        $and: [
          {
            $isArray: `${id}.value`,
          },
          {
            $eq: [
              {
                $size: `${id}.value`,
              },
              0,
            ],
          },
        ],
      },
    ],
  };
}

// FIXME: It may be not the best implementation
export const getFormattedScaleValue = ({
  question,
  value,
}) => {
  if (question.type !== QUESTION_TYPE__SCALE) {
    return null;
  }

  const {
    scaleLabels,
  } = question;

  if (!scaleLabels) {
    return value;
  }

  let formattedValue;
  const arr = [];

  each(scaleLabels, (scaleLabel, i) => {
    const index =
      i * ((question.getMaxValue() || 100) / (scaleLabels.length - 1));

    arr.push({
      index: Math.floor(index),
      scaleLabel: scaleLabel.value,
    });
  });

  each(arr, (item, i) => {
    if (item.index === Number(value)) {
      formattedValue = item.scaleLabel;

      return false;
    }
    if (item.index > value) {
      const prevItem = arr[i - 1];

      formattedValue = `${(prevItem || {}).scaleLabel} - ${item.scaleLabel}`;

      return false;
    }

    return true;
  });

  return formattedValue;
};
