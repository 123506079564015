import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import Center from '../primitives/Center';

const StyledHeader = styled.header`
  position: relative;
  /* background: ${theme('color.light')}; */
  padding: ${theme('space.3')};
  /* z-index: 2; */
  display: flex;
  justify-content: center;

  @media (max-width: 500px) {
    padding-top: ${theme('space.2')};
    padding-bottom: ${theme('space.2')};
  }

  @media (max-height: 350px) {
    /*
      We hide header when there's not enough space for the content.
      One of the use cases is when a user opens an on-screen keyboard on mobile.
    */
    display: none;
  }

  /* body:not(.has-background) & {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: ${theme('rhythm.small')};
      pointer-events: none;
      z-index: 2;
      transform: translateZ(0);
    }

    &::after {
      background: linear-gradient(0deg, hsla(var(--hue), 42%, 94%, 0), hsla(var(--hue), 42%, 94%, 1));
      bottom: -${theme('rhythm.small')};
    }
  } */
`;

const StyledCenter = styled(Center)`
  position: relative;
`;

const LayoutHeader = ({
  children,
}) => (children ? (
  <StyledHeader role="banner">
    <StyledCenter
      max={5}
      isStretched
    >
      {children}
    </StyledCenter>
  </StyledHeader>
) : null);

LayoutHeader.propTypes = {
  children: PropTypes.node,
};

LayoutHeader.defaultProps = {
  children: null,
};

export default LayoutHeader;
