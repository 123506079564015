import isArray from 'lodash/isArray';
import range from 'lodash/range';
import BaseModel from './BaseModel';
import {
  PERMISSIONS_DOMAIN_DELIMITER,
} from '../constants';

const constant = x => () => x;

/**
 * Represents a PermissionsDomain.
 * @class
 */
class PermissionsDomain extends BaseModel {
  getName() {
    return this.name;
  }

  getDomain() {
    return this._id;
  }

  static split(id) {
    const parts = id.split(PERMISSIONS_DOMAIN_DELIMITER).filter(part => !!part);
    if (parts.length === 0) {
      throw new Error(`Invalid domain ${id}`);
    }
    return parts;
  }

  static createPatternMatch(pattern) {
    if (!isArray(pattern)) {
      return this.createPatternMatch(this.split(pattern));
    }
    const normalize = original => (parts) => {
      if (typeof parts === 'string') {
        return original(this.split(parts));
      } if (!isArray(parts)) {
        throw new Error('Expected string or an array');
      }
      return original(parts);
    };
    if (pattern.length === 0) {
      return normalize(parts => parts.length === 0);
    }
    if (pattern.length === 1 && pattern[0] === '**') {
      return constant(true);
    }
    const matchMore = this.createPatternMatch(pattern.slice(1));
    return normalize((parts) => {
      if (pattern[0] === '*') {
        return parts.length > 0 && matchMore(parts.slice(1));
      }
      if (pattern[0] === '**') {
        return range(parts.length).some(i => matchMore(parts.slice(i)));
      }
      return (
        parts.length > 0 && parts[0] === pattern[0] && matchMore(parts.slice(1))
      );
    });
  }

  /**
   * Creates a selector that accepts the given domain including all sub-domains.
   * @param {String} domain - must end with domain delimiter "/"
   * @returns {Object}
   * @example
   * Users.find({ belongsTo: PermissionsDomain.selector(usersGroup.getDomain()) });
   */
  static selector(domain) {
    if (domain.charAt(domain.length - 1) !== PERMISSIONS_DOMAIN_DELIMITER) {
      throw new Error(
        `In PermissionsDomain.domainSelector() domain must end with "${PERMISSIONS_DOMAIN_DELIMITER}"`,
      );
    }
    return {
      $regex: `^${domain}`,
    };
  }

  /**
   * If domain is a string of the form 'a/b/c/d/', return an array of elements:
   *
   * [
   *   'a/',
   *   'a/b/',
   *   'a/b/c/',
   *   'a/b/c/d/',
   * ]
   */
  static domainHierarchy(domain) {
    if (domain.charAt(domain.length - 1) !== PERMISSIONS_DOMAIN_DELIMITER) {
      throw new Error(
        `In PermissionsDomain.domainHierarchy() domain must end with "${PERMISSIONS_DOMAIN_DELIMITER}"`,
      );
    }
    const parts = domain.split(PERMISSIONS_DOMAIN_DELIMITER);
    if (parts.length <= 1) {
      throw new Error(`Invalid domain ${domain}`);
    }
    const hierarchy = [];
    parts.forEach((part) => {
      if (part) {
        const previous = hierarchy[hierarchy.length - 1] || '';
        hierarchy.push(`${previous}${part}/`);
      }
    });
    return hierarchy;
  }

  /**
   * Return a domain hierarchy tree derived from the given domain ids.
   * @param {string[]} ids
   *
   * @example
   * PermissionsDomain.buildHierarchyTree(['a/', 'a/b', 'a/c']).should.deep.equal({
   *   visitors: 0,
   *   branches: {
   *     a: {
   *       visitors: 1,
   *       branches: {
   *         b: { visitors: 1 },
   *         c: { visitors: 1 },
   *       },
   *     },
   *   },
   * });
   */
  static buildHierarchyTree(ids, root = {
    visitors: 0,
  }) {
    ids.forEach((id) => {
      let node = root;
      this.split(id).forEach((part) => {
        node.branches = node.branches || {};
        node.branches[part] = node.branches[part] || {
          visitors: 0,
        };
        node = node.branches[part];
      });
      node.visitors += 1;
    });
    return root;
  }

  /**
   * Find the minimal subset giving the same permissions domain, or in other words find the minimal elements
   * with respect to the domain hierarchy in the given set.
   * @param {string[]} ids
   *
   * @example
   * PermissionsDomain.extractFundamentalDomains(['a/', 'a/b/']).should.deep.equal(['a/']);
   */
  static extractFundamentalDomains(ids) {
    const root = this.buildHierarchyTree(ids);
    const selected = [];
    const findSelected = (node, prefix) => {
      if (node.visitors > 0) {
        selected.push(prefix);
      } else if (node.branches) {
        Object.keys(node.branches).forEach((key) => {
          findSelected(
            node.branches[key],
            prefix + key + PERMISSIONS_DOMAIN_DELIMITER,
          );
        });
      }
    };
    findSelected(root, '');
    return selected;
  }

  /**
   * Given a couple of domains spaces find out their common part. In language of "domain hierarchy tree"
   * this is equivalent to ...
   * @param {string[][]}
   *
   * @example
   * PermissionsDomain.findCommonRealm([['a/', 'b/c/'], ['b/']]).should.deep.equal(['b/c/']);
   *
   * @example
   * // this will return a list of domains at which the user can both create project and users
   * PermissionsDomain.findCommonRealm([
   *   user.getDomainsWithPrimitivePermission(CREATE_PROJECTS),
   *   user.getDomainsWithPrimitivePermission(CREATE_USERS),
   * ]);
   */
  static findCommonRealm(realms) {
    const root = {};
    realms.forEach((domains) => {
      this.buildHierarchyTree(this.extractFundamentalDomains(domains), root);
    });
    if (realms.length === 0) {
      return [];
    }
    const selected = [];
    const findSelected = (node, total, prefix) => {
      if (total === realms.length) {
        selected.push(prefix);
      } else if (node.branches) {
        Object.keys(node.branches).forEach(key => findSelected(
          node.branches[key],
          total + node.branches[key].visitors,
          prefix + key + PERMISSIONS_DOMAIN_DELIMITER,
        ));
      }
    };
    findSelected(root, 0, '');
    return selected;
  }
}

PermissionsDomain.collection = 'PermissionsDomains';

export default PermissionsDomain;
