import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ThemeProviderCommon from '../common/components/ThemeProvider';
import preferences from '../store/preferences';
import theme from '../ui/theme';

const ThemeProvider = ({
  children,
}) => {
  const dispatch = useDispatch();
  const globalStateTheme = useSelector(preferences.get('theme'));

  const onThemeChange = useCallback(
    mode => dispatch(preferences.set('theme', mode)),
    [
      dispatch,
    ],
  );

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <ThemeProviderCommon
        globalStateTheme={globalStateTheme}
        onThemeChange={onThemeChange}
      >
        {children}
      </ThemeProviderCommon>
    </StyledComponentsThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
