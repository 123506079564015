import forEach from 'lodash/forEach';
import get from 'lodash/get';
import {
  plugins,
} from './plugins/register';
import parentLogger from '../../logger';
import {
  variablesById,
} from '../../constants';

const pluginsCache = {
  db: new WeakMap(),
  fhir: new WeakMap(),
};

class BaseModel {
  constructor(doc) {
    Object.assign(this, doc);
    Object.defineProperty(this, 'raw', {
      value: this.constructor.getRawDoc(doc),
    });
  }

  getTier() {
    return this.restrictedTo;
  }

  getDomain() {
    return this.belongsTo;
  }

  permissionGrantSuffices(grant) {
    if (!this.restrictedTo) {
      return true;
    }
    return !!grant && grant.tier >= this.restrictedTo;
  }

  getVariable(variableId) {
    const variable = variablesById[variableId];
    const {
      scopeName,
    } = this.constructor;
    if (
      scopeName &&
      variable &&
      variable.scopeName &&
      variable.scopeName === scopeName
    ) {
      if (variable.nativeKey) {
        return get(this, variable.nativeKey);
      }
      return this.variables && this.variables[variableId];
    }
    return undefined;
  }

  /**
   * Returns a subset of data that can be used in formula evaluation.
   */
  getFormulaScopeProperties() {
    const scope = {};
    forEach(this.constructor.properties, ({
      key,
    }) => {
      scope[key] = {
        value: this[key],
      };
    });
    return scope;
  }

  static get db() {
    if (!this.plugins.db) {
      throw new Error('BaseModel plugin "db" was not registered');
    }
    if (!pluginsCache.db.has(this)) {
      pluginsCache.db.set(this, this.plugins.db.create(this));
    }
    return pluginsCache.db.get(this);
  }

  static get fhir() {
    if (!this.plugins.fhir) {
      throw new Error('BaseModel plugin "fhir" was not registered');
    }
    if (!pluginsCache.fhir.has(this)) {
      pluginsCache.fhir.set(this, this.plugins.fhir.create(this));
    }
    return pluginsCache.fhir.get(this);
  }

  static getRawDoc(doc) {
    let rawDoc = doc;
    while (rawDoc instanceof BaseModel) {
      rawDoc = rawDoc.raw;
    }
    return rawDoc;
  }
}

BaseModel.store = 'ddp';
BaseModel.logger = parentLogger.create('models');
BaseModel.plugins = plugins;

export default BaseModel;
