import {
  isEmptyAnswer,
} from '../../../utils/question';
import Formula from '../Formula';
import {
  FORMULA_TYPE__CONTEXT_PROPERTY,
} from '../../../constants';

export class FormulaContextProperty extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.id) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const data = scope.getContextProperty(this.settings.id);
    if (isEmptyAnswer(data)) {
      return {
        error: this.constructor.NoData,
      };
    }
    return {
      value: data.value,
    };
  }

  toMongoExpression() {
    // eslint-disable-line
    return {
      $literal: '[unknown]',
    };
  }
}

Formula.types[FORMULA_TYPE__CONTEXT_PROPERTY] = FormulaContextProperty;
