import styled from 'styled-components/macro';
import React from 'react';
import Spacer from '../primitives/Spacer';
import {
  theme,
} from '../../utilsClient/cssHelpers';

// TODO: Add ARIA role="separator"?

const Component = styled.div`
  display: flex;
  align-items: center;

  &:before,
  &:after {
    content: '';
    height: 1px;
    flex-grow: 1;
    background: ${theme('color.border')};
  }

  &:before {
    /* background: linear-gradient(to right, transparent, #e8e8e8); */
  }

  &:after {
    /* background: linear-gradient(to left, transparent, #e8e8e8); */
  }
`;

export default ({
  className,
  children,
}) => (
  <Component className={className}>
    {children && <Spacer>{children}</Spacer>}
  </Component>
);
