import {
  QUESTION_TYPE__SWITCH,
} from '../../../constants';
import Question from '../Question';

export default class QuestionBoolean extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SWITCH;
  }

  getRawKeys() {
    const rawKeys = [
      'value',
    ];
    if (this.useOther()) {
      rawKeys.push('other');
    }
    if (this.useText1()) {
      rawKeys.push('text1');
    }
    if (this.useText2()) {
      rawKeys.push('text2');
    }
    return rawKeys;
  }
}

QuestionBoolean.expectedValueType = {
  type: 'boolean',
};

QuestionBoolean.editorSettings = {
  limits: false,
  choices: false,
  snippets: false,
  additional: false,
};

Question.types[QUESTION_TYPE__SWITCH] = QuestionBoolean;
